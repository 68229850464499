import {
  Box,
  CircularProgress,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import SvgIcon from "assets/svgs";
import {
  ButtonPrimary,
  ButtonPrimaryOutline,
  InputPrimary,
  Row,
} from "components/Global/styles";
import ListTokens from "components/ListTokens";
import ModalError from "components/ModalError";
import ModalInfo from "components/ModalInfo";
import ModalSuccess from "components/ModalSuccess";
import { TokenParam } from "configs/chains";
import { ethers } from "ethers";
import useIsMounted from "hooks/useIsMounted";
import { StyledImg } from "pages/transactions/styles";
import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { isMobile, isTablet } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import useGlobal from "services/hooks/useGlobal";
import useLanguage from "services/hooks/useLanguage";
import useSwap from "services/hooks/useSwap";
import { colors } from "utils/colors";
import {
  BRIDGE_CONTRACT_ADDRESS,
  bridgeTokens,
  bridgeTokensToAddress,
  estimateGas,
  getBalanceOf,
  getBalanceOfPools,
  getGasPrice,
  getTransactionCount,
  onApproveCheck,
  onApproveHandler,
  signToken,
  SignToken,
  updateTransaction,
} from "utils/helpers/contracts";
import {
  convertToBigint,
  convertToString,
  multiplyOperator,
} from "utils/helpers/custom-operator";
import { isMaxDecimal } from "utils/helpers/helpers";
import { checksumAddress, limitText } from "utils/helpers/string";
import { useAccount, useSwitchChain } from "wagmi";
import {
  BackGroundWrapper,
  ButtonSwap,
  Card,
  ContainerContent,
  Description,
  SwapForm,
  TextError,
  TextEstimate,
  TextNoted,
  TextPrice,
  TextTitle,
  Title,
  TitleCard,
} from "./styles";

const useStyles = makeStyles(() => ({
  menuPaper: {
    backgroundColor: colors.backgroundCard,
    color: colors.textLight,
  },
}));

const BridgePage = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useLanguage(["bridge"]);

  const {
    amount,
    setAmount,
    setBalanceSend,
    setBalanceReceive,
    balanceSend,
    balanceReceive,
    chainList,
    setTokenSend,
    setTokenReceive,
    tokenSend,
    tokenReceive,
    gasFee,
    setGasFee,
    isApproved,
    setApproved,
    loadingGasFee,
    setLoadingGasFee,
    signTokenData,
    setSignTokenData,
  } = useSwap();
  const { open: openWeb3 } = useWeb3Modal();
  const { setLoading, loading } = useGlobal();
  const { address, chainId, isConnected } = useAccount();
  const { switchChainAsync } = useSwitchChain();
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const refEstimateGas = useRef<string>("");
  const timeoutEstimateGas = useRef<NodeJS.Timeout | null>(null);

  const [modalSuccess, setModalSuccess] = useState<{
    visible: boolean;
    description: string | JSX.Element | React.ReactNode;
  }>({ visible: false, description: "" });

  const [modalError, setModalError] = useState<{
    visible: boolean;
    description: string | JSX.Element | React.ReactNode;
  }>({ visible: false, description: "" });

  const [modalInfo, setModalInfo] = useState<{
    visible: boolean;
    description: string | JSX.Element | React.ReactNode;
  }>({ visible: false, description: "" });

  const [anchorSwapToken, setAnchorSwapToken] = useState<null | HTMLElement>(
    null
  );
  const openSwapTokenDropdown = Boolean(anchorSwapToken);
  const onOpenSwapTokenDropdown = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorSwapToken(event.currentTarget);
  };
  const onCloseSwapTokenDropdown = () => {
    setAnchorSwapToken(null);
  };

  const swapTokenDropdownName = "dropdown-swap-token";
  const disabled = !isConnected;
  const isEnough =
    tokenSend?.address && convertToBigint(amount) > convertToBigint(balanceSend)
      ? false
      : true;

  useEffect(() => {
    if (isMobile || isTablet) {
      setModalInfo({
        visible: true,
        description: (
          <text>
            {t("important_notice_text_1", { ns: "translation" })}
            <br />
            <br />
            {t("important_notice_text_2", { ns: "translation" })}{" "}
            <span style={{ color: colors.primary }}>
              BSC {t("chain", { ns: "translation" })}
            </span>{" "}
            {t("important_notice_text_3", { ns: "translation" })}
          </text>
        ),
      });
    }

    return () => {
      handleInit();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      await handleInit();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, chainId, address, chainList]);

  useEffect(() => {
    if (!isMounted) return;

    (async () => {
      await handleGetBalanceSend(tokenSend?.address);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenSend?.address]);

  useEffect(() => {
    if (!isConnected) return;
    if (!address) return;

    (async () => {
      await handleGetBalanceReceive();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, address]);

  useEffect(() => {
    (async () => {
      if (!tokenSend?.address || !address || Number(amount) === 0 || !isEnough)
        return;

      const walletAddress = checksumAddress(address);

      const signTokenData = await signToken({
        amount,
        contractAddress: tokenSend.address,
        walletAddress,
      });
      setSignTokenData(signTokenData);
      await handleEstimateGas({ signTokenData });
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, tokenSend, tokenReceive, address, isEnough]);

  const handleInit = async (): Promise<void> => {
    setTokenSend(undefined);
    setTokenReceive(undefined);
    setBalanceSend("");
    setBalanceReceive("");
    setGasFee(undefined);
    setSignTokenData(undefined);
  };

  const handleEstimateGas = async (data: {
    milliSeconds?: number;
    signTokenData: SignToken;
  }): Promise<unknown> => {
    const { milliSeconds = 1000, signTokenData } = data;

    const _handleEstimate = async () => {
      try {
        if (
          amount &&
          Number(amount) !== 0 &&
          tokenSend?.address &&
          // tokenReceive?.address &&
          address &&
          !isMaxDecimal(amount)
        ) {
          setLoadingGasFee(true);

          const walletAddress = checksumAddress(address);
          const [approveRes, data, nonce, gasPrice] = await Promise.all([
            onApproveCheck({
              contractAddress: tokenSend.address,
              walletAddress,
              amount: convertToBigint(amount),
              swapAddress: BRIDGE_CONTRACT_ADDRESS,
            }),
            bridgeTokens({
              ...signTokenData,
              tokenSendAddress: tokenSend.address,
            }),
            getTransactionCount({ walletAddress }),
            getGasPrice(),
          ]);

          setApproved(!!approveRes);

          if (approveRes) {
            const gasLimit = await estimateGas({
              walletAddress,
              nonce,
              data,
              to: BRIDGE_CONTRACT_ADDRESS,
            });

            const _gasFee = convertToString(gasLimit * gasPrice);
            setGasFee(_gasFee);
            refEstimateGas.current = _gasFee;
          } else {
            setGasFee("");
          }
        } else {
          setGasFee("");
        }
      } catch (error) {
        setApproved(false);
        setGasFee("");
        console.log("error", error);
        setModalError({
          visible: true,
          description: t("cannot_get_estimates_for_gas_price", {
            ns: "translation",
          }),
        });
      } finally {
        setLoadingGasFee(false);
      }
    };

    timeoutEstimateGas.current && clearTimeout(timeoutEstimateGas.current);
    const promise = new Promise((resolve, reject) => {
      timeoutEstimateGas.current = setTimeout(async () => {
        await _handleEstimate();
        resolve(true);
      }, milliSeconds);
    });
    return promise;
  };

  const handleGetBalanceSend = async (tokenAddress?: `0x${string}`) => {
    if (tokenAddress && address) {
      const balanceSwap = (await getBalanceOf({
        contractAddress: tokenAddress,
        walletAddress: address,
      })) as bigint | null;

      setBalanceSend(convertToString(balanceSwap || 0n));
    } else {
      setBalanceSend("");
    }
  };

  const handleGetBalanceReceive = async () => {
    if (address) {
      const balanceBridge = await getBalanceOfPools({ walletAddress: address });
      setBalanceReceive(convertToString(balanceBridge.value));
    } else {
      setBalanceReceive("");
    }
  };

  const onMax = (): void => {
    setAmount(balanceSend);
  };

  const onApprove = async (): Promise<void> => {
    try {
      if (!tokenSend || !tokenSend?.address || !address) return;

      const walletAddress = checksumAddress(address);

      setLoading({ visible: true });
      await onApproveHandler({
        contractAddress: tokenSend.address,
        amount: ethers.parseEther("1000000000"),
        swapAddress: BRIDGE_CONTRACT_ADDRESS,
      });
      const signTokenData = await signToken({
        amount,
        contractAddress: tokenSend.address,
        walletAddress,
      });
      await handleEstimateGas({ milliSeconds: 3000, signTokenData });
      await onBridge(signTokenData);
    } catch (error) {
      console.log("error", error);
      setModalError({
        visible: true,
        description: t("cannot_approve", { ns: "translation" }),
      });
    } finally {
      loading && setLoading({ visible: false });
    }
  };

  const onBridge = async (data: SignToken): Promise<void> => {
    try {
      if (
        // !tokenReceive ||
        // !tokenReceive?.address ||
        !tokenSend?.address ||
        !address
      )
        return;

      setLoading({ visible: true });
      // NOTE -> Send transaction then call to backend
      const { txHash, wait } = await bridgeTokensToAddress({
        ...data,
        tokenSendAddress: tokenSend.address,
        fee: multiplyOperator(refEstimateGas.current, "0.2").bigint,
      });

      await wait();
      // NOTE -> Wait for transaction done then call to backend
      try {
        await updateTransaction({ txHash });
      } catch (e) {
        setModalSuccess({
          visible: true,
          description: t("transaction_is_being_processed", {
            ns: "translation",
          }),
        });
      }
      setAmount("");
      setApproved(false);
      setGasFee(undefined);
      setSignTokenData(undefined);
      await handleGetBalanceSend(tokenSend?.address);
      await handleGetBalanceReceive();

      setModalSuccess({
        visible: true,
        description: t("bridge_successfully", { ns: "translation" }),
      });
    } catch (error) {
      console.log("error", error);

      setModalError({
        visible: true,
        description: t("cannot_bridge", { ns: "translation" }),
      });
    } finally {
      setLoading({ visible: false });
    }
  };

  const onChangeToken = (params: TokenParam) => {
    onCloseSwapTokenDropdown();
    //set new Token here
    setTokenSend({
      name: params.name,
      address: params.address,
      urlIcon: params.urlIcon,
    });
    setAmount("");
  };

  return (
    <BackGroundWrapper>
      <ContainerContent>
        <Box style={{ maxWidth: "100%" }}>
          <Title>{t("title", { ns: "bridge" })}</Title>
          <Box top={3} />
          <Description>{t("description", { ns: "bridge" })}</Description>
          <Box top={3} />
          <Card>
            <TitleCard>{t("bridge", { ns: "translation" })}</TitleCard>
            <Box mt={5} />
            <SwapForm error={!isEnough}>
              <Row justifyContent="space-between" gap={10}>
                <TextTitle>{t("bridge_from", { ns: "translation" })}</TextTitle>
                <TextTitle>
                  {t("balance", { ns: "translation" })}:{" "}
                  {isSmallScreen
                    ? limitText(String(balanceSend || 0), 20)
                    : balanceSend || 0}
                </TextTitle>
              </Row>
              <Box mt={1} />
              <Row gap={3}>
                <div style={{ flex: 1 }}>
                  <InputPrimary
                    style={{ width: "100%" }}
                    value={amount}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      // Define a regex pattern to allow digits from 0 to 9 and the dot character
                      const numericRegex = /^[0-9]+(\.[0-9]*)?$/;
                      // Check if the input value matches the regex pattern
                      if (numericRegex.test(inputValue)) {
                        setAmount(inputValue);
                      } else if (event.target.value.length === 0) {
                        setAmount("");
                      }
                    }}
                    size={"medium" as any}
                    disabled={disabled || !tokenSend?.address}
                    placeholder="0.00"
                  />
                </div>

                <ButtonPrimary
                  size="medium"
                  disabled={disabled}
                  onClick={onMax}
                >
                  {t("max", { ns: "translation" })}
                </ButtonPrimary>

                <ButtonPrimaryOutline
                  size="medium"
                  // disabled={chainId === 97}
                  id={"button-swap"}
                  aria-controls={
                    openSwapTokenDropdown ? swapTokenDropdownName : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={openSwapTokenDropdown ? "true" : undefined}
                  disabled={!isConnected}
                  onClick={async (event) => {
                    // chainId !== 56)
                    if (
                      chainId !==
                      Number(process.env.REACT_APP_DEFAULT_BSC_CHAIN_ID)
                    ) {
                      await switchChainAsync({
                        chainId: Number(
                          process.env.REACT_APP_DEFAULT_BSC_CHAIN_ID
                        ),
                      });
                    } else {
                      onOpenSwapTokenDropdown(event);
                    }
                  }}
                >
                  {tokenSend?.urlIcon && (
                    <StyledImg
                      src={require(`../../assets/tokens/${tokenSend.urlIcon}`)}
                      width="24px"
                      className="ms-2"
                      style={{ borderRadius: "100%" }}
                    />
                  )}
                  {tokenSend
                    ? tokenSend.name
                    : t("select_token", { ns: "translation" })}
                </ButtonPrimaryOutline>
                <Menu
                  id={swapTokenDropdownName}
                  anchorEl={anchorSwapToken}
                  open={openSwapTokenDropdown}
                  onClose={onCloseSwapTokenDropdown}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  classes={{ paper: classes.menuPaper }}
                >
                  {chainList
                    .at(0)
                    ?.tokens.filter(
                      (token) =>
                        token.address !==
                        process.env.REACT_APP_CONTRACT_TOKEN_CORK
                    )
                    .map((tokenItem, tokenIndex) => (
                      <MenuItem
                        key={tokenIndex}
                        onClick={() => onChangeToken(tokenItem)}
                        style={{
                          gap: 10,
                        }}
                        className="notranslate"
                      >
                        <StyledImg
                          src={
                            tokenItem.urlIcon
                              ? require(`../../assets/tokens/${tokenItem.urlIcon}`)
                              : "https://chainlist.org/unknown-logo.png"
                          }
                          width="24px"
                          className="ms-2"
                          style={{ borderRadius: "100%" }}
                        />
                        {tokenItem.name}
                      </MenuItem>
                    ))}
                </Menu>
              </Row>
            </SwapForm>
            {!isEnough && (
              <TextError>
                {t("insufficient_balance", { ns: "translation" })}
              </TextError>
            )}
            {isMaxDecimal(amount) && (
              <TextError>
                {t("exceed_the_decimal", { ns: "translation" })}
              </TextError>
            )}
            <Box mt={3} />
            <Row justifyContent="center">
              <ButtonSwap>
                <SvgIcon
                  iconName="swap"
                  width={20}
                  height={20}
                  color={colors.primary}
                />
              </ButtonSwap>
            </Row>
            <Box mt={3} />
            <SwapForm>
              <Row justifyContent="space-between" gap={10}>
                <TextTitle>{t("to", { ns: "translation" })}</TextTitle>
                <TextTitle>
                  {t("balance", { ns: "translation" })}:{" "}
                  {isSmallScreen
                    ? limitText(String(balanceReceive || 0), 20)
                    : balanceReceive || 0}
                </TextTitle>
              </Row>
              <Box mt={1} />
              <Row gap={10}>
                <div style={{ flex: 1 }}>
                  <InputPrimary
                    style={{ width: "100%" }}
                    value={amount}
                    size={"medium" as any}
                    disabled
                    placeholder="0.00"
                  />
                </div>

                <ButtonPrimaryOutline size="medium" disabled>
                  <StyledImg
                    src={"/logo512.png"}
                    width="24px"
                    className="ms-2"
                    style={{ borderRadius: "100%" }}
                  />
                  Pools
                </ButtonPrimaryOutline>
              </Row>
            </SwapForm>
            <Box mt={3} />
            <Row justifyContent="space-between">
              <TextPrice>{t("ratio", { ns: "translation" })}:</TextPrice>
              {tokenSend?.name && (
                <TextNoted>1 {tokenSend?.name} : 1 POOLS</TextNoted>
              )}
            </Row>
            <Box mt={1} />
            {gasFee && (
              <>
                <Row justifyContent="space-between">
                  <TextPrice>
                    {t("gas_free_estimated", { ns: "translation" })}:
                  </TextPrice>
                  <TextEstimate>~{gasFee} BNB</TextEstimate>
                </Row>
                <Row justifyContent="space-between">
                  <TextPrice>
                    {t("service_fee_estimated", { ns: "translation" })}:
                  </TextPrice>{" "}
                  <TextEstimate>
                    ~{multiplyOperator(gasFee, "0.2").string} BNB
                  </TextEstimate>
                </Row>
              </>
            )}

            <Box mt={5} mb={2}>
              <ButtonPrimary
                style={{
                  width: "100%",
                  backgroundColor:
                    isApproved &&
                    isConnected &&
                    chainId ===
                      Number(process.env.REACT_APP_DEFAULT_BSC_CHAIN_ID)
                      ? "#611619"
                      : undefined,
                  color:
                    isApproved &&
                    isConnected &&
                    chainId ===
                      Number(process.env.REACT_APP_DEFAULT_BSC_CHAIN_ID)
                      ? colors.white
                      : undefined,
                }}
                disabled={
                  isConnected &&
                  (!isEnough ||
                    loadingGasFee ||
                    amount.length === 0 ||
                    isMaxDecimal(amount) ||
                    chainId !==
                      Number(process.env.REACT_APP_DEFAULT_BSC_CHAIN_ID) ||
                    +amount <= 0)
                }
                onClick={async () => {
                  if (!isConnected) {
                    await openWeb3();
                  } else if (isApproved && signTokenData) {
                    onBridge(signTokenData);
                  } else if (!isApproved) {
                    onApprove();
                  }
                }}
              >
                {loadingGasFee && <CircularProgress size={24} />}
                {!isConnected
                  ? t("connect_wallet", { ns: "translation" })
                  : isApproved
                  ? t("bridge", { ns: "translation" })
                  : t("approve", { ns: "translation" })}
              </ButtonPrimary>
            </Box>
          </Card>
          <ListTokens
            type="inside"
            tokenSend={
              tokenSend?.address && tokenSend.name && tokenSend.urlIcon
                ? {
                    address: tokenSend.address,
                    name: tokenSend.name,
                    urlIcon: require(`../../assets/tokens/${tokenSend.urlIcon}`),
                  }
                : undefined
            }
            tokenReceive={{
              address: address,
              name: "POOLS",
              urlIcon: "/logo512.png",
              type: "bridge",
            }}
          />
        </Box>
      </ContainerContent>
      <ListTokens
        type="outside"
        tokenSend={
          tokenSend?.address && tokenSend.name && tokenSend.urlIcon
            ? {
                address: tokenSend.address,
                name: tokenSend.name,
                urlIcon: require(`../../assets/tokens/${tokenSend.urlIcon}`),
              }
            : undefined
        }
        tokenReceive={{
          address: address,
          name: "POOLS",
          urlIcon: "/logo512.png",
          type: "bridge",
        }}
      />

      <ModalSuccess
        open={modalSuccess.visible}
        title={t("success", { ns: "translation" })}
        description={modalSuccess.description}
        onClose={async () => {
          setModalSuccess({ ...modalSuccess, visible: false });
        }}
        onConfirm={async () => {
          navigate("/transactions");
        }}
        titleConfirm={t("detail", { ns: "translation" })}
      />

      <ModalError
        open={modalError.visible}
        title={t("error", { ns: "translation" })}
        description={modalError.description}
        onClose={() => setModalError({ ...modalError, visible: false })}
      />
      <ModalInfo
        open={modalInfo.visible}
        title={t("important_notice", { ns: "translation" })}
        description={modalInfo.description}
        onClose={() => setModalInfo({ ...modalInfo, visible: false })}
      />
    </BackGroundWrapper>
  );
};

export default BridgePage;
