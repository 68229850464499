import React from "react";
import WalletIcon from "./walletIcon";
import MetaMaskIcon from "./metaMaskIcon";
import LogoutIcon from "./logoutIcon";
import SwapIcon from "./swapIcon";
import RefreshIcon from "./refreshIcon";
import SuccessIcon from "./successIcon";
import ErrorIcon from "./errorIcon";
import InfoIcon from "./Info";
import CopyIcon from "./copy";

type IconType =
  | "wallet"
  | "meta_mask"
  | "logout"
  | "swap"
  | "refresh"
  | "success"
  | "error"
  | "info"
  | "copy";

interface SvgIconProps {
  iconName: IconType;
  color?: string;
  width?: number;
  height?: number;
}

const SvgIcon = ({ iconName, ...props }: SvgIconProps): JSX.Element => {
  switch (iconName) {
    case "wallet": {
      return <WalletIcon {...props} />;
    }
    case "meta_mask": {
      return <MetaMaskIcon {...props} />;
    }
    case "logout": {
      return <LogoutIcon {...props} />;
    }
    case "swap": {
      return <SwapIcon {...props} />;
    }
    case "refresh": {
      return <RefreshIcon {...props} />;
    }
    case "success": {
      return <SuccessIcon {...props} />;
    }
    case "error": {
      return <ErrorIcon {...props} />;
    }
    case "info": {
      return <InfoIcon {...props} />;
    }
    case "copy": {
      return <CopyIcon {...props} />;
    }
    default: {
      return <div>?</div>;
    }
  }
};

export type { IconType };
export default SvgIcon;
