import { Box, Modal, styled } from "@mui/material";
import SvgIcon from "assets/svgs";
import {
  ButtonPrimary,
  ButtonPrimaryOutline,
  Row,
} from "components/Global/styles";
import useLanguage from "services/hooks/useLanguage";
import { colors } from "utils/colors";

const BoxContainer = styled(Box)(() => ({
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 400,
  maxWidth: 1200,
  backgroundColor: colors.backgroundCard,
  border: `1px solid ${colors.border}`,
  borderRadius: 6,
  padding: 20,
  [`@media screen and (max-width: 1200px)`]: {
    minWidth: 400,
    maxWidth: 1000,
  },
  [`@media screen and (max-width: 700px)`]: {
    minWidth: 300,
    maxWidth: 400,
  },

  [`@media screen and (max-width: 400px)`]: {
    minWidth: 300,
    maxWidth: 350,
  },
}));
const Title = styled("p")(() => ({
  fontSize: 20,
  fontWeight: 600,
  color: colors.textLight,
}));

const Description = styled("p")(() => ({
  fontSize: 14,
  fontWeight: 400,
  color: colors.textLight,
}));

interface ModalSuccessProps {
  open: boolean;
  title: string | JSX.Element | React.ReactNode;
  description: string | JSX.Element | React.ReactNode;
  onClose?: () => void;
  onConfirm?: () => void;
  titleClose?: string | JSX.Element | React.ReactNode;
  titleConfirm?: string | JSX.Element | React.ReactNode;
}
const ModalSuccess = (props: ModalSuccessProps): JSX.Element => {
  const { t } = useLanguage();
  const {
    description,
    onClose,
    open,
    title,
    onConfirm,
    titleClose = t("close"),
    titleConfirm = t("confirm"),
  } = props;
  return (
    <Modal
      open={open}
      // onClose={handleClose}
    >
      <BoxContainer>
        <Row gap={10}>
          <SvgIcon
            iconName="success"
            width={80}
            height={80}
            color={colors.success}
          />
          <Title>{title}</Title>
        </Row>

        <Description>{description}</Description>

        <Box mt={5} />
        <Row flexDirection="row-reverse">
          <Row gap={"10px"}>
            <ButtonPrimary size="medium" onClick={onClose}>
              {titleClose}
            </ButtonPrimary>
            <ButtonPrimaryOutline size="medium" onClick={onConfirm}>
              {titleConfirm}
            </ButtonPrimaryOutline>
          </Row>
        </Row>
      </BoxContainer>
    </Modal>
  );
};

export default ModalSuccess;
