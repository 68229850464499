import BridgePage from "pages/bridge";
import Wrapper from "./components/Wrapper";
import HomePage from "./pages/home";
import TransactionPage from "./pages/transactions";
import { Navigate, type RouteObject } from "react-router-dom";

const routersSwap: RouteObject[] = [
  {
    element: <Wrapper />,
    children: [
      {
        path: "/*",
        element: <Navigate to={"/swap"} />,
      },
      {
        path: "/swap",
        element: <HomePage />,
      },
      {
        path: "/transactions",
        element: <TransactionPage />,
      },
    ],
  },
];

const routersBridge: RouteObject[] = [
  {
    element: <Wrapper />,
    children: [
      {
        path: "/*",
        element: <Navigate to={"/bridge"} />,
      },
      {
        path: "/bridge",
        element: <BridgePage />,
      },
      {
        path: "/transactions",
        element: <TransactionPage />,
      },
    ],
  },
];

export { routersSwap, routersBridge };
