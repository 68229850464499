import * as React from "react";
const MetaMaskIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={88}
    height={88}
    viewBox="0 0 32 32"
    {...props}
  >
    <title>{"t\u1EA3i xu\u1ED1ng-svg"}</title>
    <style>
      {
        ".s1,.s6{fill:#e27625;stroke:#e27625;stroke-linecap:round;stroke-linejoin:round;stroke-width:.4}.s6{fill:#f5841f;stroke:#f5841f}"
      }
    </style>
    <path
      id="Layer"
      d="m26.8 5-9.4 6.9 1.8-4.1z"
      style={{
        fill: "#e17726",
        stroke: "#e17726",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 0.4,
      }}
    />
    <path
      fillRule="evenodd"
      d="m12.8 7.9 1.7 4.1-9.3-7zm15 13.3-1.5 5.2-5.4-1.5 2.5-3.8zm-19.2-.1L11 25l-5.3 1.4-1.5-5.2z"
      className="s1"
    />
    <path
      fillRule="evenodd"
      d="m14.4 11.4.2 5.7-5.3-.2 1.5-2.3c0 .1 3.6-3.2 3.6-3.2zm8.3 5.5-5.3.2.2-5.7 3.6 3.3zm-11.2 4.3 2.8 2.1-3.2 1.6zm9 0 .4 3.6-3.2-1.5z"
      className="s1"
    />
    <path
      d="m20.9 24.9-3.1-1.5.2 2v.9zm-9.9 0 3 1.4-.1-.9.3-2z"
      style={{
        fill: "#d5bfb2",
        stroke: "#d5bfb2",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 0.4,
      }}
    />
    <path
      d="m14.1 19.8-2.7-.7 1.9-.9.8 1.6zm3.8 0 .8-1.6 1.9.9-2.7.7z"
      style={{
        fill: "#233447",
        stroke: "#233447",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 0.4,
      }}
    />
    <path
      fillRule="evenodd"
      d="m8.6 21.2 2.9-.1-.5 3.8zm14.9 0L21 24.9l-.4-3.8zm-2.9-2.1-1.9-.9-.7 1.7-.5-2.7 5.3-.3zM9.3 17l5.2.2-.5 2.7-.7-1.6-1.9.8z"
      style={{
        fill: "#cc6228",
        stroke: "#cc6228",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 0.4,
      }}
    />
    <path
      fillRule="evenodd"
      d="m11.4 19.1.1 2.1-2.2-4.3zm11.3-2.2-2.2 4.3v-2.1zm-7.8 1.9-.2 4.2-.6-3.2.5-2.7zm3.1 1-.6 3.2-.2-4.2.3-1.7.5 2.7z"
      style={{
        fill: "#e27525",
        stroke: "#e27525",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 0.4,
      }}
    />
    <path
      d="m17.9 19.8-.6 3.2.5.4 2.7-2.2.1-2.1zm-6.5-.8.1 2.1 2.7 2.2.5-.3-.7-3.2-2.6-.8z"
      className="s6"
    />
    <path
      d="M18 26.3v-.9l-.2-.2h-3.6l-.2.2v.9l-3-1.4 1.1.9 2.1 1.4h3.6l2.1-1.5 1-.8-2.9 1.4z"
      style={{
        fill: "#c0ac9d",
        stroke: "#c0ac9d",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 0.4,
      }}
    />
    <path
      d="m17.8 23.3-.5-.3h-2.6l-.4.4-.3 2 .2-.2h3.6l.2.2s-.2-2-.2-2.1z"
      style={{
        fill: "#161616",
        stroke: "#161616",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 0.4,
      }}
    />
    <path
      d="m27.2 12.4.8-3.8L26.8 5l-9.1 6.7 3.5 2.9 5 1.5 1.1-1.3-.5-.3.7-.7-.5-.5.7-.5-.5-.4zM4 8.6l.8 3.8-.5.4.7.5-.5.5.7.7-.5.3 1.1 1.3 5-1.4 3.5-3L5.2 5z"
      style={{
        fill: "#763e1a",
        stroke: "#763e1a",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 0.4,
      }}
    />
    <path
      d="m26.2 16.1-5-1.4 1.5 2.2-2.2 4.3H27.8zm-15.4-1.5-4.9 1.5-1.7 5.1H11.5l-2.2-4.3 1.5-2.3zm6.7 2.5.3-5.4 1.4-3.9h-6.3l1.4 3.9.3 5.4.1 1.7V23h2.7v-4.2z"
      className="s6"
    />
  </svg>
);
export default MetaMaskIcon;
