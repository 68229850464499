import { ethers } from "ethers";

const convertToBigint = (value: string): bigint => {
  try {
    let result: bigint = ethers.parseEther(value);
    return result;
  } catch (error) {
    return 0n;
  }
};

const convertToString = (value: bigint): string => {
  return ethers.formatUnits(value, 18).replace(/\.?0+$/, "");
};

const addOperator = (
  a: string,
  b: string
): { string: string; bigint: bigint; decimal: number } => {
  let result: bigint = convertToBigint(a) + convertToBigint(b);
  return {
    string: convertToString(result),
    bigint: result,
    decimal: Number(convertToString(result)),
  };
};

const subtractionOperator = (
  a: string,
  b: string
): { string: string; bigint: bigint; decimal: number } => {
  let result: bigint = convertToBigint(a) - convertToBigint(b);
  return {
    string: convertToString(result),
    bigint: result,
    decimal: Number(convertToString(result)),
  };
};

const divideOperator = (
  a: string,
  b: string
): { string: string; bigint: bigint; decimal: number } => {
  const result: bigint =
    (convertToBigint(a) * ethers.parseEther("1")) / convertToBigint(b);

  return {
    string: convertToString(result),
    bigint: result,
    decimal: Number(convertToString(result)),
  };
};

const multiplyOperator = (
  a: string,
  b: string
): { string: string; bigint: bigint; decimal: number } => {
  let result: bigint =
    (convertToBigint(a) * convertToBigint(b)) / ethers.parseEther("1");
  return {
    string: convertToString(result),
    bigint: result,
    decimal: Number(convertToString(result)),
  };
};

export {
  convertToBigint,
  convertToString,
  addOperator,
  subtractionOperator,
  divideOperator,
  multiplyOperator,
};
