import { Theme, getContrastRatio } from "@mui/material";
import { common } from "@mui/material/colors";

interface Response<T> {
  data: T;
  message: string;
}

const handleApi = async <T = any>(data: {
  api: () => Promise<Response<T>>;
  handleResponse: (res: Response<T>) => void;
  keyLoading: string;
  keyCached?: string;
  get: any;
  set: any;
}) => {
  const { api, handleResponse, keyLoading, keyCached, get, set } = data;
  try {
    keyLoading &&
      set((state: any) => {
        if (keyCached) {
          return {
            [keyLoading]: {
              ...state,
              [keyCached]: true,
            },
          };
        } else {
          return { [keyLoading]: true };
        }
      });
    const result = await api();
    handleResponse(result);
  } catch (error) {
    const errorResponse = error as Response<null>;
    const _error = {
      message: "Error",
      description: errorResponse.message,
    };

    throw new Error(JSON.stringify({ error: _error }));
  } finally {
    keyLoading &&
      set((state: any) => {
        if (keyCached) {
          return {
            [keyLoading]: {
              ...state,
              [keyCached]: false,
            },
          };
        } else {
          return { [keyLoading]: false };
        }
      });
  }
};

const getContrastAlphaColor = (theme: Theme, alpha: string | number) =>
  theme.palette.mode === "light"
    ? `rgb(0 0 0 / ${alpha})`
    : `rgb(255 255 255 / ${alpha})`;

const getContrastTextColor = (theme: Theme, background?: string) =>
  getContrastRatio(common.white, background ?? theme.palette.primary.main) >= 3
    ? common.white
    : common.black;

const timeoutDone = (milliSeconds: number) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(true);
    }, milliSeconds);
  });

/**
 *
 * @author Nguyen Quoc Tin
 * @returns boolean
 */
const isMaxDecimal = (amount: string) =>
  amount.includes(".") ? amount.split(".")[1].length > 18 : amount.length > 18;

const updateValuesOfObject =
  <T extends Record<string, any>>(data: T) =>
  (handle: (val: T) => void): T => {
    let row = 0;
    for (const key in data) {
      row++;
      handle(data[key]);
    }
    return data;
  };

const fixLanguageKo = (data: Record<string, any>) => {
  let row = 0;
  for (const key in data) {
    row++;
    if (data.hasOwnProperty(key) && data[key]) {
      let str = data[key];

      try {
        const words: string[] = str.split(/\s+/);

        let newText = words.map((word) => `<text>${word}</text>`).join(" ");
        data[key] = newText;
      } catch (error) {
        console.error("error in row json kr", row);
      }
    }
  }

  return data;
};

export {
  handleApi,
  getContrastAlphaColor,
  getContrastTextColor,
  timeoutDone,
  isMaxDecimal,
  fixLanguageKo,
  updateValuesOfObject,
};
