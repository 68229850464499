import { Box, Button, styled } from "@mui/material";
import { colors } from "../../utils/colors";
import { ButtonPrimary, Row } from "components/Global/styles";

const Text = styled("p")<{}>(() => ({
  fontSize: 16,
  fontWeight: "bold",
  textDecoration: "none",
  borderBottom: 0,
  margin: 0,
}));

const RowSwitchLanguageHeader = styled("div")<{}>(() => ({
  gap: 10,
  display: "flex",
  height: 50,
  zIndex: 99999999999999999999,

  [`@media screen and (max-width: 456px)`]: {
    display: "none",
  },
}));

const RowSwitchLanguageDropdown = styled("div")<{}>(() => ({
  gap: 10,
  display: "none",
  flexDirection: "row",
  [`@media screen and (max-width: 456px)`]: {
    display: "flex",
    height: 30,
  },
}));

const ButtonLanguage = styled("div")<{}>(() => ({
  cursor: "pointer",
  userSelect: "none",
  display: "flex",
  alignItems: "center",
}));

const TextButtonActiveLanguage = styled("div")<{}>(() => ({
  color: colors.primary,
  borderWidth: 1,
  borderBottomColor: colors.primary,
  borderBottomStyle: "solid",
  lineHeight: 1.5,
}));

const TextPlaceholder = styled("div")<{}>(() => ({
  color: colors.textPlaceholder,
  display: "flex",
  alignItems: "center",
}));

const TextButtonInActiveLanguage = styled("div")<{}>(() => ({
  color: colors.textPlaceholder,
}));

const HeaderWrapper = styled(Box)<{}>(() => ({
  position: "sticky",
  top: 0,
  //   width: "100%",
  flex: 1,
  padding: "10px 100px",
  borderBottom: "2px solid rgba(133, 133, 133, 0.1)",
  backgroundColor: colors.backgroundDark,
  boxShadow: "rgba(0, 0, 0, 0.1) 0px 5px 10px",
  // zIndex: 1000,
  [`@media screen and (max-width: 768px)`]: {
    // height: 70,
    padding: "10px 10px",
  },
}));

const RowNav = styled(Row)<{}>(() => ({
  gap: 50,
  position: "absolute",
  width: "90%",
  justifyContent: "center",
  zIndex: 999999999999999999999,

  [`@media screen and (max-width: 1120px)`]: {
    // height: 70,
    // gap: 10,
    display: "none",
  },

  [`@media screen and (max-width: 900px)`]: {
    // height: 70,
    gap: 20,
  },

  [`@media screen and (max-width: 1580px)`]: {
    // height: 70,
    gap: 20,
    width: "45%",
  },
}));

const TextNav = styled(Text)<{}>(() => ({
  gap: 50,
  display: "block",
  color: "rgb(128, 128, 128)",

  [`@media screen and (max-width: 768px)`]: {
    // height: 70,
    display: "none",
  },
}));

const TextNavDropdownItem = styled(Text)<{}>(() => ({
  gap: 50,
  display: "block",
  color: "rgb(128, 128, 128)",
}));

const StyledLogoIcon = styled("img")<{ width?: number }>(({ width }) => ({
  width,
  height: "auto",
  marginRight: 8,
}));

const StyledIcon = styled("img")<{ width?: number }>(({ width }) => ({
  width,
  height: "auto",
}));

const PopupBody = styled("div")(
  ({ theme }) => `
  width: max-content;
  padding: 12px 16px;
  margin: 10px;
  border-radius: 8px;
  border: 1px solid ${colors.border};
  background-color: ${colors.backgroundCard};
  box-shadow: ${
    theme.palette.mode === "dark"
      ? `0px 4px 8px rgb(0 0 0 / 0.7)`
      : `0px 4px 8px rgb(0 0 0 / 0.1)`
  };
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  z-index: 1;
`
);

const PopupCard = styled("div")<{}>(() => ({
  backgroundColor: "rgb(36 32 61)",
  borderRadius: 10,
  padding: 8,
  display: "flex",
}));

const Address = styled(Button)<{}>(() => ({
  borderRadius: 20,
  backgroundColor: "rgb(63 59 85)",
  color: colors.white,
  padding: "10px 12px",
  fontWeight: "bold",
  "&:hover": {
    background: "rgb(63 59 105)",
  },
}));
const ButtonLogout = styled(Button)<{}>(() => ({
  borderRadius: 20,
  backgroundColor: "rgb(63 59 85)",
  // width: 46,
  // height: 55,
  fontWeight: "bold",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "&:hover": {
    background: "rgb(63 59 105)",
  },
}));

const MetaMask = styled("div")<{}>(() => ({
  width: 100,
  height: 100,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const PopupDropdown = styled(PopupBody)<{}>(() => ({
  gap: 15,
  flexDirection: "column",
  display: "none",

  [`@media screen and (max-width: 1120px)`]: {
    display: "flex",
  },
}));

const Dropdown = styled("div")<{}>(() => ({
  display: "none",
  [`@media screen and (max-width: 1120px)`]: {
    display: "block",
  },
}));

const ButtonSwitchLanguage = styled(ButtonPrimary)(({ size }) => ({
  [`@media screen and (max-width: 1000px)`]: {
    padding: "4px 4px 4px 4px",
    gap: 2,
  },

  [`@media screen and (max-width: 1120px)`]: {
    display: "none",
  },
}));

const ButtonAddChain = styled(Button)(({ size }) => ({
  backgroundColor: colors.color1,
  padding: "4px 10px 4px 10px",
  fontWeight: 600,
  color: colors.textDark,
  gap: 5,
  borderRadius: 10,

  "&:hover": {
    backgroundColor: colors.hoverPrimary,
    color: colors.textDark,
  },
  "&:disabled": {
    backgroundColor: colors.primaryDisabled,
    color: colors.textDark,
  },

  [`@media screen and (max-width: 1000px)`]: {
    padding: "4px 4px 4px 4px",
    gap: 2,
  },

  [`@media screen and (max-width: 1120px)`]: {
    display: "none",
  },
}));

export {
  HeaderWrapper,
  StyledLogoIcon,
  Text,
  PopupBody,
  PopupCard,
  Address,
  ButtonLogout,
  MetaMask,
  RowNav,
  TextNav,
  TextNavDropdownItem,
  PopupDropdown,
  Dropdown,
  StyledIcon,
  ButtonAddChain,
  ButtonSwitchLanguage,
  TextButtonActiveLanguage,
  TextButtonInActiveLanguage,
  TextPlaceholder,
  ButtonLanguage,
  RowSwitchLanguageHeader,
  RowSwitchLanguageDropdown,
};
