const colors = {
  primary: "rgb(227, 215, 104)",
  primaryDisabled: "#857e42",
  hoverPrimary: "rgb(243, 237, 117)",
  textDark: "black",
  textLight: "white",
  textPlaceholder: "#808080",
  border: "rgb(48,43,82)",
  background: "#212121",
  backgroundDark: "rgb(16,17,20)",
  backgroundCard: "rgb(33, 33, 33)",
  white: "white",
  textWarning: "red",
  success: "#28a745",
  error: "#dc3545",
  color1: "#F6CC9A",
};

export { colors };
