import { Box, ButtonBase, IconButton, Snackbar } from "@mui/material";
import {
  Address,
  TextButtonActiveLanguage,
  ButtonAddChain,
  TextButtonInActiveLanguage,
  ButtonLogout,
  ButtonSwitchLanguage,
  Dropdown,
  HeaderWrapper,
  MetaMask,
  PopupBody,
  PopupCard,
  PopupDropdown,
  RowNav,
  StyledIcon,
  StyledLogoIcon,
  TextNav,
  TextNavDropdownItem,
  TextPlaceholder,
  ButtonLanguage,
  RowSwitchLanguageHeader,
  RowSwitchLanguageDropdown,
} from "./styles";
import { ButtonPrimary, Column, Flex, Row } from "../Global/styles";
import { NavLink } from "react-router-dom";
import { colors } from "../../utils/colors";
import { BillIcon, SwapRevertIcon } from "../Icons";
import { useAccount, useDisconnect, useSwitchChain } from "wagmi";
import { formatWallet } from "../../utils/helpers/string";
import React, { Fragment, useEffect, useState } from "react";
import { Unstable_Popup as BasePopup } from "@mui/base";
import SvgIcon from "assets/svgs";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import GuideIcon from "assets/svgs/guide";
import DropdownIcon from "assets/svgs/dropdown";
import CloseIcon from "@mui/icons-material/Close";
import { defaultChain } from "configs/configWagmi";
import { lang } from "languages";
import useLanguage from "services/hooks/useLanguage";
import { keyLang } from "utils/constants/storage";

const NavBar = (): JSX.Element => {
  const { t, i18n } = useLanguage(["language"]);
  const { address, isConnected, chainId } = useAccount();
  const { disconnectAsync } = useDisconnect();
  const { open: openWeb3 } = useWeb3Modal();
  const { switchChainAsync } = useSwitchChain();

  const [language, setLanguage] = useState<lang>("en");
  const [isPoolsExist, setIsPoolsExist] = useState<boolean>(false);
  const [openToast, setOpenToast] = useState<{
    visible: boolean;
    message?: string | JSX.Element | React.ReactNode;
  }>({ visible: false });
  const [anchorWallet, setAnchorWallet] = useState<null | HTMLElement>(null);
  const [anchorDropdownNavBar, setAnchorDropdownNavBar] =
    useState<null | HTMLElement>(null);

  const handleOpenWallet = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorWallet(anchorWallet ? null : event.currentTarget);
  };
  const handleOpenDropdownNavBar = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorDropdownNavBar(anchorDropdownNavBar ? null : event.currentTarget);
  };

  const openWallet = Boolean(anchorWallet);
  const openDropdownNavBar = Boolean(anchorDropdownNavBar);

  const walletId = openWallet ? "wallet-popup" : undefined;
  const dropdownNavBarId = openDropdownNavBar ? "dropdown-navbar" : undefined;

  useEffect(() => {
    const langStorage = localStorage.getItem(keyLang);
    if (langStorage) {
      changeLanguage(langStorage as lang);
    }
  }, []);

  useEffect(() => {
    if (chainId === Number(process.env.REACT_APP_DEFAULT_POOLS_CHAIN_ID!)) {
      setIsPoolsExist(true);
    }
  }, [chainId]);

  const handleConnectWallet = async (): Promise<void> => {
    try {
      await openWeb3();
    } catch (error) { }
  };

  const onLogout = async (): Promise<void> => {
    try {
      onHidePopup();
      await disconnectAsync();
    } catch (error) { }
  };
  const onHidePopup = (): void => {
    setAnchorWallet(null);
  };

  const changeLanguage = (lng: lang) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
    localStorage.setItem(keyLang, lng);
  };

  const onAddChainToWallet = async (): Promise<void> => {
    // switch (true) {
    //   case isConnected === false: {
    //     setOpenToast({ visible: true, message: "You need to connect wallet" });
    //     break;
    //   }
    //   case isPoolsExist === true: {
    //     setOpenToast({ visible: true, message: "This chain already exists" });
    //     break;
    //   }
    //   default: {
    //     await switchChainAsync({
    //       chainId: Number(process.env.REACT_APP_DEFAULT_POOLS_CHAIN_ID)!,
    //     });
    //     break;
    //   }
    // }

    if (isPoolsExist) {
      return setOpenToast({
        visible: true,
        message: t("this_chain_already_exists", { ns: "translation" }),
      });
    }
    if (typeof (window as any)?.ethereum !== "undefined") {
      try {
        await (window as any).ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: `0x${defaultChain.id.toString(16)}`,
              chainName: defaultChain.name,
              rpcUrls: defaultChain.rpcUrls.default.http,
              nativeCurrency: {
                decimals: defaultChain.nativeCurrency.decimals,
                name: defaultChain.nativeCurrency.name,
                symbol: defaultChain.nativeCurrency.symbol,
              },
              blockExplorerUrls: [defaultChain.blockExplorers.default.url],
            },
          ],
        });
        setIsPoolsExist(true);
      } catch (error) {
        const err = error as any;
        console.log(err?.message);
        if (err?.message === "User rejected the request.") {
          return setOpenToast({
            visible: true,
            message: t("user_rejected_the_request", { ns: "translation" }),
          });
        }
        return setOpenToast({
          visible: true,
          message: err?.message,
        });
      }
    } else {
      console.log("Metamask is not installed");
    }
  };

  const onSwitchKorea = (): void => {
    changeLanguage("ko");
  };

  const onSwitchEnglish = (): void => {
    changeLanguage("en");
  };

  const actionToast = (
    <Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setOpenToast({ visible: false })}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  const _renderNavHeader = (): JSX.Element => {
    return (
      <RowNav>
        {process.env.REACT_APP_CONFIG_BRIDGE === "true" && (
          <NavLink
            to={"/bridge"}
            style={({ isActive, isPending, isTransitioning }) => {
              return {
                color: isActive ? "rgb(243, 237, 117)" : colors.textPlaceholder,
                textDecoration: "none",
              };
            }}
          >
            <Flex justifyContent="center">
              <SwapRevertIcon width="30px" fill="currentColor" />
            </Flex>
            <TextNav>{t("bridge", { ns: "nav_bar" })}</TextNav>
          </NavLink>
        )}
        {process.env.REACT_APP_CONFIG_BRIDGE === "false" && (
          <NavLink
            to={"/swap"}
            style={({ isActive, isPending, isTransitioning }) => {
              return {
                color: isActive ? "rgb(243, 237, 117)" : colors.textPlaceholder,
                textDecoration: "none",
              };
            }}
          >
            <Flex justifyContent="center">
              <SwapRevertIcon width="30px" fill="currentColor" />
            </Flex>
            <TextNav>{t("swap", { ns: "nav_bar" })}</TextNav>
          </NavLink>
        )}
        <NavLink
          to={"/transactions"}
          style={({ isActive, isPending, isTransitioning }) => {
            return {
              color: isActive ? "rgb(243, 237, 117)" : colors.textPlaceholder,
              textDecoration: "none",
            };
          }}
        >
          <Flex justifyContent="center">
            <BillIcon width="30px" fill="currentColor" />
          </Flex>
          <TextNav>{t("transaction", { ns: "nav_bar" })}</TextNav>
        </NavLink>

        <a
          target="_blank"
          href={
            process.env.REACT_APP_CONFIG_BRIDGE === "true"
              ? "/BRIDGE-GAME-TOKENS GUIDELINES.pdf"
              : "/SWAP-CORK-TOKEN GUIDELINES.pdf"
          }
          style={{ textDecoration: "none" }}
          rel="noreferrer"
        >
          <Flex justifyContent="center">
            <GuideIcon width={30} height={30} color="rgb(128, 128, 128)" />
          </Flex>
          <TextNav>{t("guide", { ns: "nav_bar" })}</TextNav>
        </a>
      </RowNav>
    );
  };

  const _renderDropdownNav = (): JSX.Element => {
    return (
      <PopupDropdown>
        {process.env.REACT_APP_CONFIG_BRIDGE === "true" && (
          <NavLink
            to={"/bridge"}
            style={({ isActive, isPending, isTransitioning }) => {
              return {
                color: isActive ? "rgb(243, 237, 117)" : colors.textPlaceholder,
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                gap: 10,
              };
            }}
          >
            <Flex justifyContent="center">
              <SwapRevertIcon width="30px" fill="currentColor" />
            </Flex>
            <TextNavDropdownItem>
              {t("bridge", { ns: "nav_bar" })}
            </TextNavDropdownItem>
          </NavLink>
        )}
        {process.env.REACT_APP_CONFIG_BRIDGE === "false" && (
          <NavLink
            to={"/swap"}
            style={({ isActive, isPending, isTransitioning }) => {
              return {
                color: isActive ? "rgb(243, 237, 117)" : colors.textPlaceholder,
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                gap: 10,
              };
            }}
          >
            <Flex justifyContent="center">
              <SwapRevertIcon width="30px" fill="currentColor" />
            </Flex>
            <TextNavDropdownItem>
              {t("swap", { ns: "nav_bar" })}
            </TextNavDropdownItem>
          </NavLink>
        )}
        <NavLink
          to={"/transactions"}
          style={({ isActive, isPending, isTransitioning }) => {
            return {
              color: isActive ? "rgb(243, 237, 117)" : colors.textPlaceholder,
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              gap: 10,
            };
          }}
        >
          <Flex justifyContent="center">
            <BillIcon width="30px" fill="currentColor" />
          </Flex>
          <TextNavDropdownItem>
            {t("transaction", { ns: "nav_bar" })}
          </TextNavDropdownItem>
        </NavLink>

        <a
          target="_blank"
          href={
            process.env.REACT_APP_CONFIG_BRIDGE === "true"
              ? "/BRIDGE-GAME-TOKENS GUIDELINES.pdf"
              : "/SWAP-CORK-TOKEN GUIDELINES.pdf"
          }
          style={{
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            gap: 10,
          }}
          rel="noreferrer"
        >
          <Flex justifyContent="center">
            <GuideIcon width={30} height={30} color="rgb(128, 128, 128)" />
          </Flex>
          <TextNavDropdownItem>
            {t("guide", { ns: "nav_bar" })}
          </TextNavDropdownItem>
        </a>

        {process.env.REACT_APP_CONFIG_BRIDGE === "true" && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            href="#"
            onClick={onAddChainToWallet}
            style={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <Flex justifyContent="center">
              <SvgIcon iconName="meta_mask" width={30} height={30} />
            </Flex>

            <TextNavDropdownItem>
              {t("add_pools", {
                ns: "nav_bar",
                values: {
                  value:
                    Number(process.env.REACT_APP_DEFAULT_POOLS_CHAIN_ID!) ===
                      6868
                      ? "Mainnet"
                      : "Testnet",
                },
              })}
            </TextNavDropdownItem>
          </a>
        )}

        {_renderButtonSwitchLanguageDropdown()}
      </PopupDropdown>
    );
  };

  const _renderDropdownNavbar = (): JSX.Element => {
    return (
      <BasePopup
        id={dropdownNavBarId}
        open={openDropdownNavBar}
        anchor={anchorDropdownNavBar}
        onClick={() => setAnchorDropdownNavBar(null)}
      >
        {_renderDropdownNav()}
      </BasePopup>
    );
  };

  const _renderPopupWallet = (): JSX.Element => {
    return (
      <BasePopup id={walletId} open={openWallet} anchor={anchorWallet}>
        <PopupBody>
          <PopupCard>
            <MetaMask>
              <SvgIcon iconName="meta_mask" />
            </MetaMask>
            <Box>
              <Address
                onClick={() => {
                  address && navigator.clipboard.writeText(address);
                  onHidePopup();
                  setOpenToast({
                    visible: true,
                    message: t("copied_wallet_address", { ns: "translation" }),
                  });
                }}
              >
                {isConnected && address && formatWallet(address, 8)}
              </Address>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  marginTop: 10,
                }}
              >
                <ButtonLogout size="large" onClick={onLogout}>
                  <SvgIcon
                    iconName="logout"
                    width={20}
                    height={20}
                    color={colors.white}
                  />
                </ButtonLogout>
              </Box>
            </Box>
          </PopupCard>
        </PopupBody>
      </BasePopup>
    );
  };

  const _renderButtonConnectWallet = (): JSX.Element => {
    return (
      <ButtonPrimary
        onClick={(e) => {
          isConnected && address ? handleOpenWallet(e) : handleConnectWallet();
        }}
        aria-describedby={walletId}
        type="button"
        style={{ border: "1px solid white" }}
      >
        <SvgIcon iconName="wallet" width={38} />
        {isConnected && address
          ? formatWallet(address)
          : t("connect_wallet", { ns: "translation" })}
      </ButtonPrimary>
    );
  };

  const _renderButtonAddPools = (): JSX.Element | undefined => {
    if (process.env.REACT_APP_CONFIG_BRIDGE === "true") {
      return (
        <ButtonAddChain onClick={onAddChainToWallet}>
          <SvgIcon iconName="meta_mask" width={42} height={42} />
          {t("add_pools", {
            ns: "nav_bar",
            values: {
              value:
                Number(process.env.REACT_APP_DEFAULT_POOLS_CHAIN_ID!) === 6868
                  ? "Mainnet"
                  : "Testnet",
            },
          })}
        </ButtonAddChain>
      );
    }
  };

  const _renderLanguages = (): JSX.Element => {
    return (
      <>
        <ButtonLanguage onClick={onSwitchEnglish}>
          {language === "en" ? (
            <TextButtonActiveLanguage>ENG</TextButtonActiveLanguage>
          ) : (
            <TextButtonInActiveLanguage>ENG</TextButtonInActiveLanguage>
          )}
        </ButtonLanguage>
        <TextPlaceholder>/</TextPlaceholder>
        <ButtonLanguage onClick={onSwitchKorea}>
          {language === "ko" ? (
            <TextButtonActiveLanguage>KOR</TextButtonActiveLanguage>
          ) : (
            <TextButtonInActiveLanguage>KOR</TextButtonInActiveLanguage>
          )}
        </ButtonLanguage>
      </>
    );
  };

  const _renderButtonSwitchLanguage = (): JSX.Element => {
    return (
      <RowSwitchLanguageHeader>{_renderLanguages()}</RowSwitchLanguageHeader>
    );
  };

  const _renderButtonSwitchLanguageDropdown = (): JSX.Element => {
    return (
      <RowSwitchLanguageDropdown>
        {_renderLanguages()}
      </RowSwitchLanguageDropdown>
    );
  };

  return (
    <HeaderWrapper>
      <Flex alignItems="center" justifyContent="space-between">
        <Row
          style={{ alignItems: "center" }}
          aria-describedby={dropdownNavBarId}
          onClick={handleOpenDropdownNavBar}
        >
          <StyledLogoIcon
            src={require("../../assets/images/logo_winery.png")}
            width={100}
          />
          <Dropdown>
            <DropdownIcon width={30} height={30} color="white" />
          </Dropdown>
        </Row>

        {_renderNavHeader()}

        <Row gap={10}>
          {_renderButtonSwitchLanguage()}
          {_renderButtonAddPools()}
          {_renderButtonConnectWallet()}
        </Row>
      </Flex>

      {_renderPopupWallet()}
      {_renderDropdownNavbar()}

      <Snackbar
        open={openToast.visible}
        autoHideDuration={5000}
        onClose={() => setOpenToast({ visible: false })}
        message={openToast.message}
        action={actionToast}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      />
    </HeaderWrapper>
  );
};

export default NavBar;
