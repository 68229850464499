import * as React from "react";
const SwapIcon = ({
  width = 25,
  height = 25,
  color = "black",
}: {
  width?: number;
  height?: number;
  color?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 48 48"
    fill={color}
  >
    <title>{"swap-vertical-solid"}</title>
    <g data-name="Layer 2">
      <path fill="none" d="M0 0h48v48H0z" data-name="invisible box" />
      <g data-name="icons Q2">
        <path d="M24.4 33.5a2.1 2.1 0 0 0 .2-2.7 1.9 1.9 0 0 0-3-.2L17 35.2V8a2 2 0 0 0-4 0v27.2l-4.6-4.6a1.9 1.9 0 0 0-3 .2 2.1 2.1 0 0 0 .2 2.7l8 7.9a1.9 1.9 0 0 0 2.8 0ZM23.6 14.5a2.1 2.1 0 0 0-.2 2.7 1.9 1.9 0 0 0 3 .2l4.6-4.6V40a2 2 0 0 0 4 0V12.8l4.6 4.6a1.9 1.9 0 0 0 3-.2 2.1 2.1 0 0 0-.2-2.7l-8-7.9a1.9 1.9 0 0 0-2.8 0Z" />
      </g>
    </g>
  </svg>
);
export default SwapIcon;
