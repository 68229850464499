import * as React from "react";
const WalletIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    fill="none"
    className="sc-bdvvtL gKbHCZ"
    color="text"
    viewBox="0 0 25 25"
    {...props}
  >
    <path
      fill="#000"
      d="M23.438 18.75v1.953a1.955 1.955 0 0 1-1.954 1.953H4.687a3.128 3.128 0 0 1-3.125-3.125V6.25a3.128 3.128 0 0 1 3.125-3.125h14.454a1.172 1.172 0 1 1 0 2.344H4.688a.782.782 0 0 0 0 1.562h16.796c1.077 0 1.953.876 1.953 1.953v1.954h-3.906a3.91 3.91 0 0 0-3.906 3.906 3.91 3.91 0 0 0 3.906 3.906h3.907Z"
    />
    <path
      fill="#000"
      d="M23.438 12.5v4.688H19.53a2.344 2.344 0 1 1 0-4.688h3.907Z"
    />
  </svg>
);
export default WalletIcon;
