import "./App.css";
import { Box, styled } from "@mui/material/";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { routersBridge, routersSwap } from "./routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider } from "wagmi";
// import { configWagmi } from "./configs/wallets";
import { configWagmi } from "./configs/configWagmi";
import Loader from "components/Loader";

export const Layout = styled(
  Box,
  {}
)<{}>(() => ({
  background: "#fff",
  overflow: "hidden",
}));

const AppLayout = (): JSX.Element => {
  const isBridge =
    process.env.REACT_APP_CONFIG_BRIDGE === "true" ? true : false;
  const router = createBrowserRouter(isBridge ? routersBridge : routersSwap);

  return <RouterProvider router={router} />;
};

const queryClient = new QueryClient();

function App() {
  return (
    <WagmiProvider config={configWagmi}>
      <QueryClientProvider client={queryClient}>
        <Loader />
        <Layout>
          <AppLayout />
        </Layout>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
