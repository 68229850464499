import { create } from "zustand";
import ChainList, { ChainSwap } from "../../configs/chains";
import { SignToken } from "utils/helpers/contracts";

interface TokenParams {
  address?: `0x${string}`;
  name?: string;
  chainId?: number;
  urlIcon?: string;
}
interface SwapData {
  amount: string;
  isApproved: boolean;
  balanceSend: string;
  balanceReceive: string;
  tokenSend?: TokenParams;
  tokenReceive?: TokenParams;
  chainList: ChainSwap[];
  gasFee?: string;
  loadingGasFee?: boolean;
  signTokenData?: SignToken;
}
interface SwapAction {
  setAmount: (val: string) => void;
  setApproved: (val: boolean) => void;
  setBalanceSend: (val?: string) => void;
  setBalanceReceive: (val?: string) => void;
  setTokenSend: (val?: TokenParams) => void;
  setTokenReceive: (val?: TokenParams) => void;
  setGasFee: (val?: string) => void;
  setLoadingGasFee: (val?: boolean) => void;
  setSignTokenData: (val?: SignToken) => void;
}
interface ISwap extends SwapData, SwapAction {}

const initialize: SwapData = {
  amount: "",
  isApproved: false,
  balanceSend: "",
  balanceReceive: "",
  gasFee: "",
  loadingGasFee: false,
  chainList: ChainList,
};

const useSwap = create<ISwap>((set, get) => ({
  ...initialize,
  setAmount: (amount) => set({ amount }),
  setApproved: (isApproved) => set({ isApproved }),
  setBalanceSend: (balanceSend) => set({ balanceSend }),
  setBalanceReceive: (balanceReceive) => set({ balanceReceive }),
  setTokenSend: (tokenSend) => {
    if (tokenSend) {
      set({ ...get().tokenSend, tokenSend });
    } else {
      set({ tokenSend });
    }
  },
  setTokenReceive: (tokenReceive) => {
    if (tokenReceive) {
      set({ ...get().tokenReceive, tokenReceive });
    } else {
      set({ tokenReceive });
    }
  },
  setGasFee: (gasFee) => set({ gasFee }),
  setLoadingGasFee: (loadingGasFee) => set({ loadingGasFee }),
  setSignTokenData: (signTokenData) => set({ signTokenData }),
  reset: () => set(initialize),
}));

export type { SwapData, ISwap };
export default useSwap;
