import { format } from "date-fns";
import web3 from "web3";

const limitText = (value: string, length: number): string => {
  if (!value) return "";
  if (value.length < length) return value;
  return value.substring(0, length - 3) + "...";
};

const formatWallet = (walletAddress: string, length: number = 5) => {
  if (!walletAddress) return "";
  return (
    walletAddress.substring(0, length) +
    "..." +
    walletAddress.slice(walletAddress.length - length - 1, walletAddress.length)
  );
};

const formatTxHash = (txHash: string) => {
  if (!txHash) return "";
  return (
    txHash.substring(0, 14) +
    "..." +
    txHash.slice(txHash.length - 13, txHash.length)
  );
};

const formatPrice = (txHash: string, length: number = 5) => {
  if (!txHash) return "";
  if (!txHash.includes(".") || txHash.split(".")[1].length < length)
    return txHash;
  else
    return (
      txHash.split(".")[0] +
      "." +
      txHash.split(".")[1].substring(0, length) +
      "..."
    );
};

const initBaseQuery: Readonly<any> = {
  pageCurrent: 1,
  pageSize: 10,
  q: "",
  noLimit: false,
};

function timeAgo(timestamp: number) {
  const now = new Date().getTime();
  const timestampDate = new Date(timestamp).getTime();
  const secondsAgo = Math.floor((now - timestampDate) / 1000);

  if (secondsAgo < 60) {
    return `${secondsAgo}s ago`;
  } else if (secondsAgo < 3600) {
    const minutesAgo = Math.floor(secondsAgo / 60);
    return `${minutesAgo}m ago`;
  } else if (secondsAgo < 86400) {
    const hoursAgo = Math.floor(secondsAgo / 3600);
    return `${hoursAgo}h ago`;
  } else {
    const daysAgo = Math.floor(secondsAgo / 86400);
    return `${daysAgo}d ago`;
  }
}

const formattedDate = (
  date?: string,
  formatData: string = "HH:mm dd/MM/yyyy"
) => {
  return date
    ? format(new Date(date), formatData)
    : format(new Date(), formatData);
};

function convertToTitleCase(str: string = ""): string {
  // Split the string by underscores and capitalize each word
  const words = str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
  // Join the words back together with spaces
  return words.join(" ");
}

const checksumAddress = (address: `0x${string}`) =>
  web3.utils.toChecksumAddress(address) as `0x${string}`;

export {
  limitText,
  formatWallet,
  initBaseQuery,
  timeAgo,
  formatTxHash,
  formattedDate,
  formatPrice,
  convertToTitleCase,
  checksumAddress,
};
