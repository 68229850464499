import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { BackGroundWrapper } from "pages/home/styles";
import { getListBridge, getListStake, unStake } from "utils/helpers/contracts";
import { checksumAddress } from "utils/helpers/string";
import { useAccount } from "wagmi";
import useGlobal from "services/hooks/useGlobal";
import { ButtonPrimary, Flex, Row } from "components/Global/styles";
import { timeoutDone } from "utils/helpers/helpers";
import { StyledImg, TableComponent, TextValue, Title } from "./styles";
import useLanguage from "services/hooks/useLanguage";

const toDate = (unixTime: bigint) => {
  const milliseconds = Number(unixTime) * 1000;
  const dateObject = new Date(milliseconds);
  return dateObject;
};

interface ItemStake {
  startTime: bigint;
  endTime: bigint;
  apr: bigint;
  amount: bigint;
  isWithdraw: boolean;
}

interface Column {
  id: string;
  label: string;
  minWidth: number;
}

interface Transaction {
  signature: string;
  timestamp: bigint;
  totalPools: bigint;
}

const columns: Column[] = [
  { id: "#", label: "#", minWidth: 50 },
  { id: "startTime", label: "time_start", minWidth: 170 },
  { id: "endTime", label: "end_time", minWidth: 170 },
  { id: "amount", label: "amount", minWidth: 100 },
  { id: "isWithdraw", label: "withdraw", minWidth: 70 },
];

const TransactionsPage = (): JSX.Element => {
  const { setLoading } = useGlobal();
  const { address, isConnected } = useAccount();
  const { t } = useLanguage("transaction");

  const [listStake, setListStake] = useState<ItemStake[]>([]);

  useEffect(() => {
    (async () => {
      try {
        setLoading({ visible: true });
        await handleGetTransactions();
      } catch (error) {
        console.log(error);
      } finally {
        setLoading({ visible: false });
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, address]);

  const handleGetTransactions = async (): Promise<void> => {
    if (isConnected && address) {
      let listItem: ItemStake[] = [];
      if (process.env.REACT_APP_CONFIG_BRIDGE === "false") {
        listItem = (await getListStake(
          checksumAddress(address)
        )) as ItemStake[];
      } else {
        const transactions = (await getListBridge(
          checksumAddress(address)
        )) as Transaction[];
        listItem = transactions.map((transaction) => ({
          amount: transaction.totalPools,
          apr: 0n,
          startTime: transaction.timestamp,
          endTime: transaction.timestamp,
          isWithdraw: true,
        }));
      }

      setListStake(listItem.sort((a, b) => Number(b.startTime - a.startTime)));
    } else {
      setListStake([]);
    }
  };

  const onWithdrawByIndex = async (index: number) => {
    try {
      setLoading({ visible: true });
      const response = await unStake(index);
      console.log("response:", response);
      await timeoutDone(3000);
      await handleGetTransactions();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading({ visible: false });
    }
  };

  const _renderStake = (item: ItemStake, column: Column, index: number) => {
    switch (column.id) {
      case "#": {
        return <TextValue>{index + 1}</TextValue>;
      }
      case "startTime": {
        return <TextValue>{toDate(item.startTime).toLocaleString()}</TextValue>;
      }
      case "endTime": {
        return <TextValue>{toDate(item.endTime).toLocaleString()}</TextValue>;
      }
      case "amount": {
        return (
          <Row gap={10}>
            <TextValue>
              {(Number(item.amount) * 10 ** -18).toFixed(6)}{" "}
            </TextValue>
            <StyledImg
              src={"/logo512.png"}
              width="24px"
              className="ms-2"
              style={{ borderRadius: "100%" }}
            />
          </Row>
        );
      }
      case "isWithdraw": {
        const disabled = item?.isWithdraw || Date.now() / 1000 < item?.endTime;
        return (
          <ButtonPrimary
            disabled={disabled}
            onClick={async () => await onWithdrawByIndex(index)}
            size="medium"
          >
            <p style={{ color: "black" }}>
              {item?.isWithdraw
                ? t("done", { ns: "transaction" })
                : disabled
                ? t("pending", { ns: "transaction" })
                : t("withdraw", { ns: "transaction" })}
            </p>
          </ButtonPrimary>
        );
      }
      default:
        break;
    }
  };

  return (
    <BackGroundWrapper>
      <Box sx={{ width: "100%", height: "100%" }}>
        <Title>{t("title")}</Title>

        <Flex justifyContent="center" alignItems="center">
          <TableComponent isSmallScreen>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      style={{
                        minWidth: column.minWidth,
                        fontSize: 16,
                        fontWeight: "bold",
                        color: "white",
                        borderBottom: "1px solid rgb(48, 43, 82)",
                      }}
                    >
                      {t(column.label, { ns: "transaction" })}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(listStake || []).map((item, index) => {
                  return (
                    <TableRow tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        return (
                          <TableCell
                            key={column.id}
                            sx={{
                              borderBottom: 0,
                              padding: 2,
                            }}
                          >
                            {_renderStake(item, column, index)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableComponent>
        </Flex>
        <Box mb={20} />
      </Box>
    </BackGroundWrapper>
  );
};

export default TransactionsPage;
