import {
  Box,
  CircularProgress,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import {
  BackGroundWrapper,
  ButtonSwap,
  Card,
  Description,
  SwapForm,
  TextError,
  TextEstimate,
  TextNoted,
  TextPrice,
  TextTitle,
  Title,
  TitleCard,
} from "./styles";
import {
  ButtonPrimary,
  ButtonPrimaryOutline,
  Flex,
  InputPrimary,
  Row,
} from "components/Global/styles";
import useSwap from "services/hooks/useSwap";
import { colors } from "utils/colors";
import SvgIcon from "assets/svgs";
import { MouseEvent, useEffect, useRef, useState } from "react";
import { useAccount, useSwitchChain } from "wagmi";
import {
  SWAP_CONTRACT_ADDRESS,
  estimateGas,
  getBalanceOf,
  getGasPrice,
  getTransactionCount,
  onApproveCheck,
  onApproveHandler,
  swap,
  swapContractToAddress,
} from "utils/helpers/contracts";
import {
  convertToBigint,
  convertToString,
  multiplyOperator,
} from "utils/helpers/custom-operator";
import { checksumAddress, limitText } from "utils/helpers/string";
import ModalSuccess from "components/ModalSuccess";
import ModalError from "components/ModalError";
import { useNavigate } from "react-router-dom";
import useGlobal from "services/hooks/useGlobal";
import { isMaxDecimal, timeoutDone } from "utils/helpers/helpers";
import { makeStyles } from "@mui/styles";
import { TokenParam } from "configs/chains";
import useIsMounted from "hooks/useIsMounted";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { StyledImg } from "pages/transactions/styles";
import ModalInfo from "components/ModalInfo";
import { isMobile, isTablet } from "react-device-detect";
import { ContainerContent } from "pages/bridge/styles";
import ListTokens from "components/ListTokens";
import useLanguage from "services/hooks/useLanguage";

const useStyles = makeStyles(() => ({
  menuPaper: {
    backgroundColor: colors.backgroundCard,
    color: colors.textLight,
  },
}));

const HomePage = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useLanguage("swap");

  const {
    amount,
    setAmount,
    setBalanceSend,
    setBalanceReceive,
    balanceSend,
    balanceReceive,
    chainList,
    setTokenSend,
    setTokenReceive,
    tokenSend,
    tokenReceive,
    gasFee,
    setGasFee,
    isApproved,
    setApproved,
    loadingGasFee,
    setLoadingGasFee,
  } = useSwap();
  const { setLoading, loading } = useGlobal();
  const { open: openWeb3 } = useWeb3Modal();
  const { address, chainId, isConnected } = useAccount();
  const { switchChainAsync } = useSwitchChain();
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const refEstimateGas = useRef<string>("");
  const timeoutEstimateGas = useRef<NodeJS.Timeout | null>(null);
  const refSwitchChain = useRef<NodeJS.Timeout | null>(null);

  const [modalSuccess, setModalSuccess] = useState<{
    visible: boolean;
    description: string | JSX.Element | React.ReactNode;
  }>({ visible: false, description: "" });

  const [modalError, setModalError] = useState<{
    visible: boolean;
    description: string | JSX.Element | React.ReactNode;
  }>({ visible: false, description: "" });

  const [modalInfo, setModalInfo] = useState<{
    visible: boolean;
    description: string | JSX.Element | React.ReactNode;
  }>({ visible: false, description: "" });

  const [anchorSwapToken, setAnchorSwapToken] = useState<null | HTMLElement>(
    null
  );
  const openSwapTokenDropdown = Boolean(anchorSwapToken);
  const onOpenSwapTokenDropdown = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorSwapToken(event.currentTarget);
  };
  const onCloseSwapTokenDropdown = () => {
    setAnchorSwapToken(null);
  };

  const swapTokenDropdownName = "dropdown-swap-token";
  const disabled = !isConnected;
  const isEnough =
    tokenSend?.address && convertToBigint(amount) > convertToBigint(balanceSend)
      ? false
      : true;

  const chainTokenSendDefault = chainList.at(0)?.tokens.at(0);

  useEffect(() => {
    if (isMobile || isTablet) {
      setModalInfo({
        visible: true,
        description: (
          <text>
            {t("important_notice_text_1", { ns: "translation" })}
            <br />
            <br />
            {t("important_notice_text_2", { ns: "translation" })}{" "}
            <span style={{ color: colors.primary }}>
              BSC {t("chain", { ns: "translation" })}
            </span>{" "}
            {t("important_notice_text_3", { ns: "translation" })}
          </text>
        ),
      });
    }

    return () => {
      setAmount("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await handleInit();
      } catch (error) {
        console.log("error", error);
        setTokenSend(undefined);
        setTokenReceive(undefined);
        setBalanceSend("");
        setBalanceReceive("");

        setModalError({
          visible: true,
          description: `${t("error", { ns: "translation" })} ${error}`,
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, chainId, address, chainList]);

  useEffect(() => {
    if (!isMounted) return;

    (async () => {
      await handleGetBalanceSend(tokenSend?.address);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenSend?.address]);

  useEffect(() => {
    if (!isMounted) return;

    (async () => {
      await handleGetBalanceReceive(tokenReceive?.address);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenReceive?.address]);

  useEffect(() => {
    (async () => {
      await handleEstimateGas();
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, tokenSend, tokenReceive, address, isEnough]);

  const handleEstimateGas = async (milliSeconds: number = 1000) => {
    const _handleEstimate = async () => {
      try {
        if (
          amount &&
          Number(amount) !== 0 &&
          tokenSend?.address &&
          tokenReceive?.address &&
          address &&
          !isMaxDecimal(amount) &&
          isEnough
        ) {
          setLoadingGasFee(true);

          const walletAddress = checksumAddress(address);
          const [approveRes, data, nonce, gasPrice] = await Promise.all([
            onApproveCheck({
              contractAddress: tokenSend.address,
              walletAddress,
              amount: convertToBigint(amount),
            }),
            swap({ amount: convertToBigint(amount) }),
            getTransactionCount({ walletAddress }),
            getGasPrice(),
          ]);

          setApproved(!!approveRes);

          if (approveRes) {
            const gasLimit = await estimateGas({
              walletAddress,
              nonce,
              data,
              to: SWAP_CONTRACT_ADDRESS,
            });
            const _gasFee = convertToString(gasLimit * gasPrice);
            setGasFee(_gasFee);
            refEstimateGas.current = _gasFee;
          }
        } else {
          setGasFee("");
          refEstimateGas.current = "";
        }
      } catch (error) {
        setApproved(false);
        setGasFee("");
        console.log("error", error);
        setModalError({
          visible: true,
          description: t("cannot_get_estimates_for_gas_price", {
            ns: "translation",
          }),
        });
      } finally {
        setLoadingGasFee(false);
      }
    };

    timeoutEstimateGas.current && clearTimeout(timeoutEstimateGas.current);
    const promise = new Promise((resolve, reject) => {
      timeoutEstimateGas.current = setTimeout(async () => {
        await _handleEstimate();
        resolve(true);
      }, milliSeconds);
    });
    return promise;
  };

  const handleInit = async (): Promise<void> => {
    if (isConnected && chainId && address) {
      if (chainId === Number(process.env.REACT_APP_DEFAULT_BSC_CHAIN_ID)!) {
        const tokenSend = chainList.at(0)?.tokens.at(0);
        if (tokenSend) {
          setTokenSend({
            address: tokenSend.address,
            name: tokenSend.name,
            chainId,
            urlIcon: tokenSend.urlIcon,
          });

          const tokenReceive = tokenSend.listSwap.at(0);
          if (tokenReceive) {
            setTokenReceive({
              address: tokenReceive.address,
              name: tokenReceive.name,
              chainId: Number(process.env.REACT_APP_DEFAULT_POOLS_CHAIN_ID)!,
            });
          }
        }
      } else {
        refSwitchChain.current && clearTimeout(refSwitchChain.current);

        refSwitchChain.current = setTimeout(async () => {
          try {
            await switchChainAsync({
              chainId: Number(process.env.REACT_APP_DEFAULT_BSC_CHAIN_ID)!,
            });
          } catch (error) {
            setTokenSend(undefined);
            setTokenReceive(undefined);
            setBalanceSend("");
            setBalanceReceive("");
            setModalError({
              visible: true,
              description: t("error_cannot_switch_chain", {
                ns: "translation",
                values: {
                  chainValue: process.env.REACT_APP_DEFAULT_BSC_CHAIN_NAME,
                },
              }),
            });
          }
        }, 1000);
      }
    } else {
      setTokenSend(undefined);
      setTokenReceive(undefined);
      setBalanceSend("");
      setBalanceReceive("");
    }
  };

  const handleGetBalanceSend = async (tokenAddress?: `0x${string}`) => {
    if (tokenAddress && address) {
      const balanceSwap = (await getBalanceOf({
        contractAddress: tokenAddress,
        walletAddress: address,
      })) as bigint | null;
      setBalanceSend(convertToString(balanceSwap || 0n));
    } else {
      setBalanceSend("");
    }
  };

  const handleGetBalanceReceive = async (tokenAddress?: `0x${string}`) => {
    if (tokenAddress && address) {
      const balanceSwap = (await getBalanceOf({
        contractAddress: tokenAddress,
        walletAddress: address,
      })) as bigint | null;
      setBalanceReceive(convertToString(balanceSwap || 0n));
    } else {
      setBalanceReceive("");
    }
  };

  const onMax = (): void => {
    setAmount(balanceSend);
  };

  const onApprove = async (): Promise<void> => {
    try {
      if (!tokenSend || !tokenSend?.address) return;

      setLoading({ visible: true });
      await onApproveHandler({
        contractAddress: tokenSend.address,
        amount: convertToBigint(amount),
      });

      await handleEstimateGas(3000);
      await onSwap();
    } catch (error) {
      console.log("error", error);

      setModalError({
        visible: true,
        description: t("cannot_approve", { ns: "translation" }),
      });
    } finally {
      loading && setLoading({ visible: false });
    }
  };

  const onSwap = async (): Promise<void> => {
    try {
      if (!tokenReceive) return;
      setLoading({ visible: true });

      await swapContractToAddress({
        amount: convertToBigint(amount),
        fee: multiplyOperator(refEstimateGas.current, "0.2").bigint,
      });
      await timeoutDone(3000);
      setAmount("");
      setApproved(false);
      await handleGetBalanceSend(tokenSend?.address);
      await handleGetBalanceReceive(tokenReceive?.address);
      setModalSuccess({
        visible: true,
        description: t("swap_successfully", { ns: "translation" }),
      });
    } catch (error) {
      console.log("error", error);

      setModalError({
        visible: true,
        description: t("cannot_swap", { ns: "translation" }),
      });
    } finally {
      setLoading({ visible: false });
    }
  };

  const onChangeToken = (params: TokenParam) => {
    onCloseSwapTokenDropdown();
    //set new Token here
    setTokenSend({ name: params.name, address: params.address });
    setAmount("");
    const tokenReceive = params.listSwap.at(0);
    tokenReceive &&
      setTokenReceive({
        chainId: Number(process.env.REACT_APP_DEFAULT_POOLS_CHAIN_ID)!,
        name: tokenReceive.name,
        address: tokenReceive.address,
      });

    // await switchChainAsync({ chainId: 97 });
  };

  return (
    <BackGroundWrapper>
      <ContainerContent>
        <Box style={{ maxWidth: "100%" }}>
          <Title>{t("title", { ns: "swap" })}</Title>
          <Box top={3} />
          <Description>{t("description", { ns: "swap" })}</Description>
          <Box top={3} />
          <Card>
            <TitleCard>{t("swap", { ns: "translation" })}</TitleCard>
            <Box mt={5} />
            <SwapForm error={!isEnough}>
              <Row justifyContent="space-between">
                <TextTitle>{t("swap_from", { ns: "translation" })}</TextTitle>
                <TextTitle>
                  {t("balance", { ns: "translation" })}:{" "}
                  {isSmallScreen
                    ? limitText(String(balanceSend || 0), 20)
                    : balanceSend || 0}
                </TextTitle>
              </Row>
              <Box mt={1} />
              <Row gap={3}>
                <div style={{ flex: 1 }}>
                  <InputPrimary
                    style={{ width: "100%" }}
                    value={amount}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      // Define a regex pattern to allow digits from 0 to 9 and the dot character
                      const numericRegex = /^[0-9]+(\.[0-9]*)?$/;
                      // Check if the input value matches the regex pattern
                      if (numericRegex.test(inputValue)) {
                        setAmount(inputValue);
                      } else if (event.target.value.length === 0) {
                        setAmount("");
                      }
                    }}
                    size={"medium" as any}
                    disabled={disabled || !tokenSend?.address}
                    placeholder="0.00"
                  />
                </div>
                <ButtonPrimary
                  size="medium"
                  disabled={disabled}
                  onClick={onMax}
                >
                  {t("max", { ns: "translation" })}
                </ButtonPrimary>

                <ButtonPrimaryOutline
                  size="medium"
                  // disabled={chainId === 97}
                  id={"button-swap"}
                  aria-controls={
                    openSwapTokenDropdown ? swapTokenDropdownName : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={openSwapTokenDropdown ? "true" : undefined}
                  // disabled={!isConnected}
                  disabled={
                    !isConnected ||
                    chainId ===
                      Number(process.env.REACT_APP_DEFAULT_BSC_CHAIN_ID)!
                  }
                  onClick={async (event) => {
                    if (
                      chainId !==
                      Number(process.env.REACT_APP_DEFAULT_BSC_CHAIN_ID!)
                    ) {
                      await switchChainAsync({
                        chainId: Number(
                          process.env.REACT_APP_DEFAULT_BSC_CHAIN_ID
                        )!,
                      });
                    } else {
                      onOpenSwapTokenDropdown(event);
                    }
                  }}
                >
                  {tokenSend && (
                    <StyledImg
                      src={require(`../../assets/tokens/${tokenSend.urlIcon}`)}
                      width="24px"
                      className="ms-2"
                      style={{ borderRadius: "100%" }}
                    />
                  )}
                  {/* {tokenSend ? tokenSend.name : "Select Token"} */}
                  {chainList.at(0)?.tokens.at(0)?.name || "?"}
                </ButtonPrimaryOutline>
                <Menu
                  id={swapTokenDropdownName}
                  anchorEl={anchorSwapToken}
                  open={openSwapTokenDropdown}
                  onClose={onCloseSwapTokenDropdown}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  classes={{ paper: classes.menuPaper }}
                >
                  {chainList.at(0)?.tokens.map((tokenItem, tokenIndex) => (
                    <MenuItem
                      key={tokenIndex}
                      onClick={() => onChangeToken(tokenItem)}
                    >
                      {tokenItem.name}
                    </MenuItem>
                  ))}
                </Menu>
              </Row>
            </SwapForm>
            {!isEnough && (
              <TextError>
                {t("insufficient_balance", { ns: "translation" })}
              </TextError>
            )}
            {isMaxDecimal(amount) && (
              <TextError>
                {t("exceed_the_decimal", { ns: "translation" })}
              </TextError>
            )}
            <Box mt={3} />
            <Row justifyContent="center">
              <ButtonSwap>
                <SvgIcon
                  iconName="swap"
                  width={20}
                  height={20}
                  color={colors.primary}
                />
              </ButtonSwap>
            </Row>
            <Box mt={3} />
            <SwapForm>
              <Row justifyContent="space-between">
                <TextTitle>{t("to", { ns: "translation" })}</TextTitle>
                <TextTitle>
                  {t("balance", { ns: "translation" })}:{" "}
                  {isSmallScreen
                    ? limitText(String(balanceReceive || 0), 20)
                    : balanceReceive || 0}
                </TextTitle>
              </Row>
              <Box mt={1} />
              <Row gap={10}>
                <div style={{ flex: 1 }}>
                  <InputPrimary
                    style={{ width: "100%" }}
                    value={amount}
                    size={"medium" as any}
                    disabled
                    placeholder="0.00"
                  />
                </div>

                <ButtonPrimaryOutline size="medium" disabled>
                  <StyledImg
                    src={"/logo512.png"}
                    width="24px"
                    className="ms-2"
                    style={{ borderRadius: "100%" }}
                  />
                  DPOOLS
                </ButtonPrimaryOutline>
              </Row>
            </SwapForm>
            <Box mt={3} />
            <Row justifyContent="space-between">
              <TextPrice>{t("ratio", { ns: "translation" })}:</TextPrice>
              {tokenSend?.name && (
                <TextNoted>1 {tokenSend?.name} : 1 DPOOLS</TextNoted>
              )}
            </Row>
            <Box mt={1} />
            {gasFee && (
              <Row justifyContent="space-between">
                <Box>
                  <Box>
                    <TextPrice>
                      {t("total_fee_estimated", { ns: "translation" })}:
                    </TextPrice>
                  </Box>
                  <Box>
                    <TextPrice>
                      {t("swap_fee_gas_fee", { ns: "translation" })}
                    </TextPrice>
                  </Box>
                </Box>
                <Flex alignItems="center">
                  <TextEstimate>~{gasFee} BNB</TextEstimate>
                </Flex>
              </Row>
            )}
            <Box mt={5} mb={2}>
              <ButtonPrimary
                style={{
                  width: "100%",
                  backgroundColor:
                    isApproved &&
                    isConnected &&
                    chainId ===
                      Number(process.env.REACT_APP_DEFAULT_BSC_CHAIN_ID)
                      ? "#611619"
                      : undefined,
                  color:
                    isApproved &&
                    isConnected &&
                    chainId ===
                      Number(process.env.REACT_APP_DEFAULT_BSC_CHAIN_ID)
                      ? colors.white
                      : undefined,
                }}
                disabled={
                  isConnected &&
                  (!isEnough ||
                    loadingGasFee ||
                    amount.length === 0 ||
                    isMaxDecimal(amount) ||
                    chainId !==
                      Number(process.env.REACT_APP_DEFAULT_BSC_CHAIN_ID) ||
                    +amount === 0)
                }
                onClick={async () => {
                  if (!isConnected) {
                    await openWeb3();
                  } else if (isApproved) {
                    onSwap();
                  } else {
                    onApprove();
                  }
                }}
              >
                {loadingGasFee && <CircularProgress size={24} />}
                {!isConnected
                  ? t("connect_wallet", { ns: "translation" })
                  : isApproved
                  ? t("swap", { ns: "translation" })
                  : t("approve", { ns: "translation" })}
              </ButtonPrimary>
            </Box>
          </Card>
          <ListTokens
            type="inside"
            tokenSend={
              // tokenSend?.address && tokenSend.name && tokenSend.urlIcon
              //   ? {
              //       address: tokenSend.address,
              //       name: tokenSend.name,
              //       urlIcon: require(`../../assets/tokens/${tokenSend.urlIcon}`),
              //     }
              //   : undefined
              chainTokenSendDefault
                ? {
                    address: chainTokenSendDefault.address,
                    name: chainTokenSendDefault.name,
                    urlIcon: require(`../../assets/tokens/${chainTokenSendDefault.urlIcon}`),
                  }
                : undefined
            }
            tokenReceive={{
              address: process.env
                .REACT_APP_CONTRACT_TOKEN_DPOOLS! as `0x${string}`,
              name: "DPOOLS",
              urlIcon: "/logo512.png",
              type: "swap",
            }}
          />
        </Box>
      </ContainerContent>
      <ListTokens
        type="outside"
        tokenSend={
          // tokenSend?.address && tokenSend.name && tokenSend.urlIcon
          //   ? {
          //       address: tokenSend.address,
          //       name: tokenSend.name,
          //       urlIcon: require(`../../assets/tokens/${tokenSend.urlIcon}`),
          //     }
          //   : undefined
          chainTokenSendDefault
            ? {
                address: chainTokenSendDefault.address,
                name: chainTokenSendDefault.name,
                urlIcon: require(`../../assets/tokens/${chainTokenSendDefault.urlIcon}`),
              }
            : undefined
        }
        tokenReceive={{
          address: process.env
            .REACT_APP_CONTRACT_TOKEN_DPOOLS! as `0x${string}`,
          name: "DPOOLS",
          urlIcon: "/logo512.png",
          type: "swap",
        }}
      />

      <ModalSuccess
        open={modalSuccess.visible}
        title={t("success", { ns: "translation" })}
        description={modalSuccess.description}
        onClose={async () => {
          setModalSuccess({ ...modalSuccess, visible: false });
        }}
        onConfirm={async () => {
          navigate("/transactions");
        }}
        titleConfirm={t("detail", { ns: "translation" })}
      />

      <ModalError
        open={modalError.visible}
        title={t("error", { ns: "translation" })}
        description={modalError.description}
        onClose={() => setModalError({ ...modalError, visible: false })}
      />
      <ModalInfo
        open={modalInfo.visible}
        title={t("important_notice", { ns: "translation" })}
        description={modalInfo.description}
        onClose={() => setModalInfo({ ...modalInfo, visible: false })}
      />
    </BackGroundWrapper>
  );
};

export default HomePage;
