import {
  estimateGas as estimateGasWagmi,
  getBalance as getBalanceWagmi,
  getGasPrice as getGasPriceWagmi,
  getTransactionCount as getTransactionCountWagmi,
  readContract,
  waitForTransactionReceipt,
  writeContract,
} from "@wagmi/core";
import axios from "axios";
import { configWagmi } from "configs/configWagmi";
import BridgeABI from "contracts/BridgeABI.json";
import ERC20Abi from "contracts/ERC20.json";
import SwapABI from "contracts/SwapABI.json";
import WithdrawBridgeABI from "contracts/WithdrawBridgeABI.json";
import { Interface } from "ethers";
import Web3 from "web3";
import { convertToString } from "./custom-operator";

const SWAP_CONTRACT_ADDRESS = process.env
  .REACT_APP_SWAP_CONTRACT_ADDRESS! as `0x${string}`;

const BRIDGE_CONTRACT_ADDRESS = process.env
  .REACT_APP_BRIDGE_CONTRACT_ADDRESS! as `0x${string}`;

const WITHDRAW_POOLS_CONTRACT_ADDRESS = process.env
  .REACT_APP_WITHDRAW_POOLS_CONTRACT_ADDRESS! as `0x${string}`;

interface SignToken {
  amount: string;
  blockNumber: number;
  dPoolsPrice: string;
  signature: string;
}

const getBalanceOf = async (data: {
  contractAddress: `0x${string}`;
  walletAddress: `0x${string}`;
}) => {
  console.log("===>getBalanceOf");
  const { contractAddress, walletAddress } = data;

  return await readContract(configWagmi, {
    abi: ERC20Abi,
    address: contractAddress,
    functionName: "balanceOf",
    args: [walletAddress],
  });
};

const getBalanceOfPools = async (data: { walletAddress: `0x${string}` }) => {
  console.log("===>getBalanceOfPools");
  const { walletAddress } = data;

  return await getBalanceWagmi(configWagmi, {
    address: walletAddress,
    chainId: +process.env.REACT_APP_DEFAULT_POOLS_CHAIN_ID!,
  });
};

const onApproveCheck = async (data: {
  contractAddress: `0x${string}`;
  walletAddress: `0x${string}`;
  amount: bigint;
  swapAddress?: `0x${string}`;
}) => {
  console.log("===>onApproveCheck");

  const {
    contractAddress,
    walletAddress,
    amount,
    swapAddress = SWAP_CONTRACT_ADDRESS,
  } = data;

  const response = (await readContract(configWagmi, {
    abi: ERC20Abi,
    address: contractAddress,
    functionName: "allowance",
    args: [walletAddress, swapAddress],
  })) as bigint;
  return response >= amount;
};

const swap = async (data: { amount: bigint }) => {
  console.log("===>swap encodeFunctionData");
  const { amount } = data;

  const iContract = new Interface(SwapABI);
  const encodeData = iContract.encodeFunctionData("swap", [amount]);
  return encodeData;
};

const swapContractToAddress = async (data: {
  amount: bigint;
  fee?: bigint;
}) => {
  console.log("===>swapContractToAddress");
  const { amount, fee } = data;

  return await writeContract(configWagmi, {
    abi: SwapABI,
    address: SWAP_CONTRACT_ADDRESS,
    functionName: "swap",
    args: [amount],
    value: fee,
  });
};

const bridgeTokens = async (
  data: SignToken & { tokenSendAddress: `0x${string}` }
) => {
  console.log("===>bridgeTokens");
  const { amount, blockNumber, dPoolsPrice, signature, tokenSendAddress } =
    data;

  const iContract = new Interface(BridgeABI);
  const encodeData = iContract.encodeFunctionData("bridgeTokens", [
    tokenSendAddress,
    amount,
    dPoolsPrice,
    blockNumber,
    signature,
  ]);
  return encodeData;
};

const bridgeTokensToAddress = async (
  data: SignToken & { tokenSendAddress: `0x${string}`; fee: bigint }
) => {
  console.log("===>bridgeTokensToAddress", data, convertToString(data.fee));
  const { amount, blockNumber, dPoolsPrice, signature, tokenSendAddress, fee } =
    data;

  const txHash = await writeContract(configWagmi, {
    abi: BridgeABI,
    address: BRIDGE_CONTRACT_ADDRESS,
    functionName: "bridgeTokens",
    args: [tokenSendAddress, amount, dPoolsPrice, blockNumber, signature],
    value: fee,
  });
  return {
    txHash,
    wait: () => waitForTransactionReceipt(configWagmi, { hash: txHash }),
  };
};

const signToken = async (data: {
  walletAddress: `0x${string}`;
  contractAddress: `0x${string}`;
  amount: string;
}): Promise<SignToken> => {
  console.log("===>signToken");

  try {
    const result = await axios({
      url: `${process.env.REACT_APP_URL_BACKEND_POOL}/bridge/sign`,
      method: "POST",
      data,
    });
    if (result.data.statusCode === 200 && result.data.data) {
      return result.data.data;
    }
    throw new Error();
  } catch (error) {
    throw new Error("Cannot sign token");
  }
};

const updateTransaction = async (data: {
  txHash: string;
}): Promise<unknown> => {
  console.log("===>updateTransaction");

  try {
    const result = await axios({
      url: `${process.env.REACT_APP_URL_BACKEND_POOL}/bridge/update-transaction`,
      method: "POST",
      data,
    });
    if (result.data.statusCode === 200 && result.data.data) {
      return result.data.data;
    }
    throw new Error();
  } catch (error) {
    throw new Error("Cannot update transaction");
  }
};

const getTransactionCount = async (data: { walletAddress: `0x${string}` }) => {
  console.log("===>getTransactionCount");
  const { walletAddress } = data;

  return await getTransactionCountWagmi(configWagmi, {
    address: walletAddress,
  });
};

const getGasPrice = async () => {
  console.log("===>getGasPrice");

  return await getGasPriceWagmi(configWagmi);
};

const estimateGas = async (params: {
  walletAddress: `0x${string}`;
  nonce: number;
  data: any;
  to: `0x${string}`;
}) => {
  console.log("===>estimateGas");
  const { walletAddress, nonce, data, to = SWAP_CONTRACT_ADDRESS } = params;

  return await estimateGasWagmi(configWagmi, {
    account: walletAddress,
    nonce,
    data,
    to,
  });
};

const onApproveHandler = async (data: {
  contractAddress: `0x${string}`;
  amount: bigint;
  swapAddress?: `0x${string}`;
}) => {
  console.log("===>onApproveHandler");

  const { contractAddress, amount, swapAddress = SWAP_CONTRACT_ADDRESS } = data;

  return await writeContract(configWagmi, {
    abi: ERC20Abi,
    address: contractAddress,
    functionName: "approve",
    args: [swapAddress, amount],
  });
};

const getListStake = async (walletAddress: `0x${string}`) => {
  console.log("===>getListStake");

  return await readContract(configWagmi, {
    abi: SwapABI,
    address: SWAP_CONTRACT_ADDRESS,
    functionName: "listStake",
    args: [walletAddress],
    chainId: +process.env.REACT_APP_DEFAULT_BSC_CHAIN_ID!,
  });
};

const getListBridge = async (walletAddress: `0x${string}`) => {
  console.log("===>getListBridge");

  return await readContract(configWagmi, {
    abi: WithdrawBridgeABI,
    address: WITHDRAW_POOLS_CONTRACT_ADDRESS,
    functionName: "listBridge",
    args: [walletAddress],
    chainId: +process.env.REACT_APP_DEFAULT_POOLS_CHAIN_ID!,
  });
};

const unStake = async (index: number) => {
  console.log("===>unStake");

  return await writeContract(configWagmi, {
    abi: SwapABI,
    address: SWAP_CONTRACT_ADDRESS,
    functionName: "unstake",
    args: [index],
  });
};

const convertToWei = (amount: number) =>
  Web3.utils.toWei(amount.toString(), "ether");

export {
  BRIDGE_CONTRACT_ADDRESS,
  bridgeTokens,
  bridgeTokensToAddress,
  convertToWei,
  estimateGas,
  getBalanceOf,
  getBalanceOfPools,
  getGasPrice,
  getListBridge,
  getListStake,
  getTransactionCount,
  onApproveCheck,
  onApproveHandler,
  signToken,
  swap,
  SWAP_CONTRACT_ADDRESS,
  swapContractToAddress,
  unStake,
  updateTransaction,
  WITHDRAW_POOLS_CONTRACT_ADDRESS,
};
export type { SignToken };
