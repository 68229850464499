import { create } from "zustand";

interface GlobalData {
  loading: {
    visible: boolean;
    title?: string;
  };
}
interface GlobalAction {
  setLoading: (data: { visible: boolean; title?: string }) => void;
}
interface IGlobal extends GlobalData, GlobalAction {}

const initialize: GlobalData = {
  loading: {
    visible: false,
  },
};

const useGlobal = create<IGlobal>((set) => ({
  ...initialize,
  setLoading: (val) => {
    set({ loading: val });
  },
  reset: () => set(initialize),
}));

export type { GlobalData, IGlobal };
export default useGlobal;
