import { useState, Fragment } from "react";
import SvgIcon from "assets/svgs";
import { Column, Row } from "components/Global/styles";
import { StyledImg } from "pages/transactions/styles";
import React from "react";
import { colors } from "utils/colors";
import { styled, IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useLanguage from "services/hooks/useLanguage";

interface ListTokensProps {
  type: "inside" | "outside";
  tokenSend?: {
    name: string;
    urlIcon: string;
    address?: `0x${string}`;
  };
  tokenReceive?: {
    name: string;
    urlIcon: string;
    address?: `0x${string}`;
    type: "swap" | "bridge";
  };
}

const url_bsc_scan = `${process.env.REACT_APP_BSC_SCAN_CHAIN}/address`;
const url_pools_scan = `${process.env.REACT_APP_POOLS_SCAN_CHAIN}/address`;

const ListTokens = (props: ListTokensProps): JSX.Element => {
  const { type, tokenSend, tokenReceive } = props;
  const { t } = useLanguage(["translation"]);

  const [openToast, setOpenToast] = useState<boolean>(false);

  const actionToast = (
    <Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setOpenToast(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  const _renderInside = (): JSX.Element | null => {
    if (type === "inside") {
      return (
        <ContainerTokensInside>
          {tokenSend && tokenSend.address && (
            <ItemToken>
              <Row
                alignItems="center"
                gap={10}
                style={{ height: 50, marginTop: 10 }}
              >
                {tokenSend?.urlIcon && (
                  <StyledImg
                    src={tokenSend.urlIcon}
                    width="24px"
                    className="ms-2"
                    style={{ borderRadius: "100%" }}
                  />
                )}
                {tokenSend?.name && (
                  <DetailTokenName>
                    {tokenSend.name} {t("token", { ns: "translation" })}
                  </DetailTokenName>
                )}
                {tokenSend?.address && (
                  <ButtonCopy
                    onClick={() => {
                      tokenSend.address &&
                        navigator.clipboard.writeText(tokenSend.address);
                      setOpenToast(true);
                    }}
                  >
                    <SvgIcon iconName="copy" color="white" />
                  </ButtonCopy>
                )}
              </Row>
              <Row>
                <DetailAddress>
                  <a
                    href={`${url_bsc_scan}/${tokenSend.address}`}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    {tokenSend.address}
                  </a>
                </DetailAddress>
              </Row>
            </ItemToken>
          )}
          {tokenReceive && tokenReceive.address && (
            <ItemToken>
              <Row
                alignItems="center"
                gap={10}
                style={{ height: 50, marginTop: 10 }}
              >
                <StyledImg
                  src={tokenReceive.urlIcon}
                  width="24px"
                  className="ms-2"
                  style={{ borderRadius: "100%" }}
                />
                <DetailTokenName>
                  {tokenReceive.name} {t("token", { ns: "translation" })}
                </DetailTokenName>

                <ButtonCopy
                  onClick={() => {
                    tokenReceive.address &&
                      navigator.clipboard.writeText(tokenReceive.address);
                    setOpenToast(true);
                  }}
                >
                  <SvgIcon iconName="copy" color="white" />
                </ButtonCopy>
              </Row>
              <Row>
                <DetailAddress>
                  {tokenReceive.type === "swap" ? (
                    <a
                      href={`${url_bsc_scan}/${tokenReceive.address}`}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      {tokenReceive.address}
                    </a>
                  ) : (
                    <a
                      href={`${url_pools_scan}/${tokenReceive.address}`}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      {tokenReceive.address}
                    </a>
                  )}
                </DetailAddress>
              </Row>
            </ItemToken>
          )}
        </ContainerTokensInside>
      );
    }

    return null;
  };

  const _renderOutSide = (): JSX.Element | null => {
    if (type === "outside") {
      return (
        <ContainerTokensOutside>
          {tokenSend && tokenSend?.address && (
            <ItemToken>
              <Row
                alignItems="center"
                gap={10}
                style={{ height: 50, marginTop: 10 }}
              >
                <StyledImg
                  src={tokenSend.urlIcon}
                  width="24px"
                  className="ms-2"
                  style={{ borderRadius: "100%" }}
                />
                <DetailTokenName>
                  {tokenSend.name} {t("token", { ns: "translation" })}
                </DetailTokenName>
                <ButtonCopy
                  onClick={() => {
                    tokenSend.address &&
                      navigator.clipboard.writeText(tokenSend.address);
                    setOpenToast(true);
                  }}
                >
                  <SvgIcon iconName="copy" color="white" />
                </ButtonCopy>
              </Row>
              <Row>
                <a
                  href={`${url_bsc_scan}/${tokenSend.address}`}
                  style={{ textDecoration: "none" }}
                >
                  <DetailAddress>{tokenSend.address}</DetailAddress>
                </a>
              </Row>
            </ItemToken>
          )}
          {tokenReceive && tokenReceive.address && (
            <ItemToken>
              <Row
                alignItems="center"
                gap={10}
                style={{ height: 50, marginTop: 10 }}
              >
                <StyledImg
                  src={tokenReceive.urlIcon}
                  width="24px"
                  className="ms-2"
                  style={{ borderRadius: "100%" }}
                />
                <DetailTokenName>
                  {tokenReceive.name} {t("token", { ns: "translation" })}
                </DetailTokenName>

                <ButtonCopy
                  onClick={() => {
                    tokenReceive.address &&
                      navigator.clipboard.writeText(tokenReceive.address);
                    setOpenToast(true);
                  }}
                >
                  <SvgIcon iconName="copy" color="white" />
                </ButtonCopy>
              </Row>
              <Row>
                <DetailAddress>
                  {tokenReceive.type === "swap" ? (
                    <a
                      href={`${url_bsc_scan}/${tokenReceive.address}`}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      {tokenReceive.address}
                    </a>
                  ) : (
                    <a
                      href={`${url_pools_scan}/${tokenReceive.address}`}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      {tokenReceive.address}
                    </a>
                  )}
                </DetailAddress>
              </Row>
            </ItemToken>
          )}
        </ContainerTokensOutside>
      );
    }

    return null;
  };

  return (
    <>
      {_renderInside()}
      {_renderOutSide()}
      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={() => setOpenToast(false)}
        message={t("copied_token_address", { ns: "translation" })}
        action={actionToast}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      />
    </>
  );
};

const DetailAddress = styled("div")(() => ({
  borderRadius: 20,
  border: `1px solid ${colors.textPlaceholder}`,
  color: "white",
  fontWeight: "500",
  fontSize: 16,
  padding: "8px 8px",

  [`@media screen and (max-width: 440px)`]: {
    marginRight: 20,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const DetailTokenName = styled("text")(() => ({
  fontWeight: "700",
  fontSize: 16,
  color: "white",
}));

const ButtonCopy = styled("div")(() => ({
  cursor: "pointer",
  userSelect: "none",
}));

const ContainerTokensOutside = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  gap: 20,
  marginTop: 10,
  [`@media screen and (max-width: 840px)`]: {
    display: "none",
  },
}));

const ContainerTokensInside = styled("div")(() => ({
  display: "none",
  margin: 10,
  maxWidth: "100%",

  [`@media screen and (max-width: 840px)`]: {
    display: "block",
    width: "100%",
  },
}));

const ItemToken = styled(Column)(() => ({
  // [`@media screen and (max-width: 840px)`]: {
  //   display: "flex",
  //   justifyContent: "center",
  //   flexDirection: "row",
  // },
  maxWidth: "100%",
}));

export default ListTokens;
