import en from "./data/en.json";
import ko from "./data/ko.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { fixLanguageKo, updateValuesOfObject } from "utils/helpers/helpers";

//update language kr add tag <text>
const dataKoJSON = updateValuesOfObject(ko)(fixLanguageKo);

// Translation files location
const resources = {
  en,
  ko: dataKoJSON,
} as const;

type lang = keyof typeof resources;

i18n
  //   .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export type { lang };
export default i18n;
