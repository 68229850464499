import { Suspense } from "react";
import { useOutlet } from "react-router-dom";
import { Box } from "@mui/material";
import NavBar from "../Navbar";

const Wrapper = () => {
  const outlet = useOutlet();

  return (
    <Box>
      <NavBar />
      <Box className="container">
        <Suspense>{outlet}</Suspense>
      </Box>
    </Box>
  );
};

export default Wrapper;
