interface TokenParam {
  address: `0x${string}`;
  name: string;
  urlIcon: string;
  listSwap: { name: string; address: `0x${string}` }[];
}

interface ChainSwap {
  name: string;
  chainId: number;
  urlIcon: string;
  tokens: TokenParam[];
}

const listSwap = [
  {
    name: "DPOOLS",
    address: process.env.REACT_APP_CONTRACT_TOKEN_DPOOLS! as `0x${string}`,
  },
];

const chains: ChainSwap[] = [
  // test net
  {
    name: "BNB",
    chainId: Number(process.env.REACT_APP_DEFAULT_BSC_CHAIN_ID!),
    urlIcon: "https://chainlist.org/unknown-logo.png",
    tokens: [
      {
        address: process.env.REACT_APP_CONTRACT_TOKEN_CORK! as `0x${string}`,
        name: "CORK",
        urlIcon: "CORK.png",
        listSwap,
      },
      // {
      //   address: process.env.REACT_APP_CONTRACT_TOKEN_CHTS! as `0x${string}`,
      //   name: "CHTS",
      //   urlIcon: "CHTS.png",
      //   listSwap,
      // },
      {
        address: process.env.REACT_APP_CONTRACT_TOKEN_RPS! as `0x${string}`,
        name: "LRPS",
        urlIcon: "LRPS.png",
        listSwap,
      },
      {
        address: process.env.REACT_APP_CONTRACT_TOKEN_DOZEN! as `0x${string}`,
        name: "DOZEN",
        urlIcon: "DOZEN.png",
        listSwap,
      },
      {
        address: process.env.REACT_APP_CONTRACT_TOKEN_BGOF! as `0x${string}`,
        name: "BGOF",
        urlIcon: "BGOF.png",
        listSwap,
      },
      {
        address: process.env.REACT_APP_CONTRACT_TOKEN_BFGT! as `0x${string}`,
        name: "BFGT",
        urlIcon: "BFGT.png",
        listSwap,
      },
    ],
  },
];

export type { ChainSwap, TokenParam };
export default chains;
