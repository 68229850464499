import { TableContainer, styled } from "@mui/material";
import { colors } from "utils/colors";

const StyledImg = styled("img")<{ width?: string | number }>(({ width }) => ({
  width: width ? width : "100%",
  height: "auto",
}));

const Title = styled("p")(() => ({
  fontSize: 34,
  fontWeight: "bold",
  color: colors.textLight,
  textAlign: "center",
}));

const TextValue = styled("p")(() => ({
  fontSize: 15,
  fontWeight: "400",
  margin: 0,
  color: colors.textLight,
}));

const TableComponent = styled(TableContainer)<{
  isSmallScreen: boolean;
}>(({ isSmallScreen }) => ({
  padding: isSmallScreen ? 0 : "30px 25px 10px 25px",
  backgroundColor: "#212121",
  boxShadow: "rgba(0, 0, 0, 0.2) 0px 5px 15px",
  borderRadius: "10px",
  maxWidth: 800,
  margin: 10,
  // width: "100%",

  // [`@media screen and (max-width: 559px)`]: {
  //   overFlow: "auto",
  // },
}));

export { StyledImg, Title, TextValue, TableComponent };
