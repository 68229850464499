import { Box, CircularProgress, Modal } from "@mui/material";
import useGlobal from "services/hooks/useGlobal";
import { colors } from "utils/colors";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: colors.backgroundCard,
  border: `1px solid ${colors.border}`,
  boxShadow: 24,
  borderRadius: 6,
  p: 4,
};

const Loader = (): JSX.Element => {
  const { loading } = useGlobal();

  return (
    <Modal
      open={Boolean(loading.visible)}
      // onClose={handleClose}
    >
      <Box sx={style}>
        <CircularProgress />
      </Box>
    </Modal>
  );
};

export default Loader;
