import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { defineChain } from "viem";
import { CreateConfigParameters } from "wagmi";

const PoolsTestNet = defineChain({
  id: 12345,
  name: "Pools Testnet",
  network: "Pools Testnet",
  nativeCurrency: {
    decimals: 18,
    name: "Pools",
    symbol: "Pools",
  },
  rpcUrls: {
    default: {
      http: ["https://rpc-testnet.poolsmobility.com"],
      webSocket: ["wss://rpc-testnet.poolsmobility.com"],
    },
    public: {
      http: ["https://rpc-testnet.poolsmobility.com"],
      webSocket: ["wss://rpc-testnet.poolsmobility.com"],
    },
  },
  blockExplorers: {
    default: {
      name: "Explorer",
      url: "https://scan-testnet.poolsmobility.com",
    },
  },
  contracts: {
    PoolsPhoneNFT: {
      address: process.env.REACT_APP_POOLS_NFT_CONTRACT,
      //blockCreated: ,
    },
  },
});

const BSCTestNet = defineChain({
  id: 97,
  name: "Binance Smart Chain Testnet",
  nativeCurrency: {
    decimals: 18,
    name: "BNB",
    symbol: "tBNB",
  },
  rpcUrls: {
    default: { http: ["https://data-seed-prebsc-1-s1.bnbchain.org:8545"] },
  },
  blockExplorers: {
    default: {
      name: "BscScan",
      url: "https://testnet.bscscan.com",
      apiUrl: "https://testnet.bscscan.com/api",
    },
  },
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11",
      blockCreated: 17422483,
    },
  },
  testnet: true,
  custom: {
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png",
  },
});

const BSCMainnet = defineChain({
  id: 56,
  name: "Binance Smart Chain Mainnet",
  nativeCurrency: {
    decimals: 18,
    name: "BNB",
    symbol: "tBNB",
  },
  rpcUrls: {
    default: { http: ["https://binance.llamarpc.com"] },
  },
  blockExplorers: {
    default: {
      name: "BscScan",
      url: "https://bscscan.com",
      apiUrl: "https://bscscan.com/api",
    },
  },
  custom: {
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png",
  },
});

const Pools = defineChain({
  id: 6868,
  name: "Pools Mainnet",
  network: "Pools Mainnet",
  nativeCurrency: {
    decimals: 18,
    name: "Pools",
    symbol: "Pools",
  },
  rpcUrls: {
    default: {
      http: ["https://rpc.poolsmobility.com"],
      webSocket: ["wss://scan.poolsmobility.com"],
    },
    public: {
      http: ["https://scan.poolsmobility.com"],
      webSocket: ["wss://scan.poolsmobility.com"],
    },
  },
  blockExplorers: {
    default: { name: "Explorer", url: "https://scan.poolsmobility.com" },
  },
  contracts: {
    PoolsPhoneNFT: {
      address: process.env.REACT_APP_POOLS_NFT_CONTRACT,
      //blockCreated: ,
    },
  },
});

// 1. Get projectId at https://cloud.walletconnect.com
if (!process.env.REACT_APP_PROJECT_ID) {
  alert("projectId null");
}
const projectId = process.env.REACT_APP_PROJECT_ID || "";

// 2. Create wagmiConfig
const metadata = {
  name: "Pools Phone",
  description: "Pools Phone Agent",
  url: process.env.REACT_APP_DOMAIN_API || "", // origin must match your domain & subdomain
  icons: [],
  // icons: [import.meta.env.VITE_WEB_LINK_ICON],
};
const defaultChain =
  Number(process.env.REACT_APP_DEFAULT_POOLS_CHAIN_ID) === 12345
    ? PoolsTestNet
    : Pools;
const defaultChainBSC =
  Number(process.env.REACT_APP_DEFAULT_BSC_CHAIN_ID) === 97
    ? BSCTestNet
    : BSCMainnet;

const chains: CreateConfigParameters["chains"] = [
  defaultChain,
  defaultChainBSC,
];

const configWagmi = defaultWagmiConfig({
  chains, // required
  projectId, // required
  metadata, // required
  enableWalletConnect: true, // Optional - true by default
  enableInjected: true, // Optional - true by default
  enableEIP6963: true, // Optional - true by default
  enableCoinbase: true, // Optional - true by default
  // ...wagmiOptions // Optional - Override createConfig parameters
});

// 3. Create modal
createWeb3Modal({
  wagmiConfig: configWagmi,
  projectId,
  allowUnsupportedChain: true,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  // excludeWalletIds: [
  //   "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
  // ],

  // customWallets: [
  //   {
  //     id: "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
  //     name: "MetaMask",
  //     homepage: "https://metamask.io/",
  //     image_url:
  //       "https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/512px-MetaMask_Fox.svg.png?20220831120339",
  //     mobile_link:
  //       process.env.REACT_APP_CONFIG_BRIDGE === "true"
  //         ? "https://metamask.app.link/dapp/bridge-dev.poolsmobility.com/bridge"
  //         : "https://metamask.app.link/dapp/swap-dev.poolsmobility.com/swap",
  //     desktop_link: null,
  //     webapp_link: null,
  //     app_store: "https://apps.apple.com/us/app/metamask/id1438144202",
  //     play_store: "https://play.google.com/store/apps/details?id=io.metamask",
  //   },
  // ],
});

export { configWagmi, projectId, chains, PoolsTestNet, defaultChain };
