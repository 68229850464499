import * as React from "react";
const GuideIcon = ({
  width = 800,
  height = 800,
  color = "black",
}: {
  width?: number;
  height?: number;
  color?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style>
        {`.a{fill:none;stroke:${color};stroke-linecap:round;stroke-linejoin:round;}.b{fill:${color};}`}
      </style>
    </defs>
    <path
      className="a"
      d="M8.4,6.5v35a2,2,0,0,0,2,2h2.33V4.5H10.4A2,2,0,0,0,8.4,6.5Z"
    />
    <path
      className="a"
      d="M12.73,4.5v39H37.6a2,2,0,0,0,2-2V6.5a2,2,0,0,0-2-2Z"
    />
    <ellipse className="b" cx={25.5314} cy={33.6676} rx={0.8226} ry={0.75} />
    <path
      className="a"
      d="M18.3013,20.0485A6.4323,6.4323,0,0,1,20.26,15.2859,7.2741,7.2741,0,0,1,25.4841,13.5c3.9179,0,7.1829,2.9767,7.1829,6.5485a6.4323,6.4323,0,0,1-1.9589,4.7626c-1.6324,1.1907-5.2239,2.9767-5.2239,5.6556"
    />
  </svg>
);
export default GuideIcon;
