import { styled, Button } from "@mui/material";
import { colors } from "../../utils/colors";

const Flex = styled("div")<{
  alignItems?:
    | "center"
    | "end"
    | "flex-end"
    | "flex-start"
    | "self-end"
    | "self-start"
    | "start";
  justifyContent?:
    | "center"
    | "end"
    | "flex-end"
    | "flex-start"
    | "start"
    | "space-around"
    | "space-between"
    | "space-evenly"
    | "stretch";
  flexWrap?: "nowrap" | "wrap" | "wrap-reverse";
}>(({ justifyContent, alignItems, flexWrap }) => ({
  display: "flex",
  alignItems: alignItems ? alignItems : undefined,
  justifyContent: justifyContent ? justifyContent : undefined,
  flexWrap: flexWrap ? flexWrap : undefined,
}));

const ButtonPrimary = styled(Button)(({ size }) => ({
  backgroundColor: colors.primary,
  padding: 10,
  fontWeight: 600,
  color: colors.textDark,
  gap: 5,
  borderRadius: 10,
  "&:hover": {
    backgroundColor: colors.hoverPrimary,
    color: colors.textDark,
  },
  "&:disabled": {
    backgroundColor: colors.primaryDisabled,
    color: colors.textDark,
  },
  height: size === "small" ? 30 : size === "medium" ? 40 : 50,
}));

const ButtonPrimaryOutline = styled(Button)(({ size, disabled }) => ({
  border: `1px solid ${colors.primary}`,
  padding: 10,
  fontWeight: 600,
  color: colors.textLight,
  gap: 5,
  borderRadius: 10,
  "&:hover": {
    backgroundColor: colors.hoverPrimary,
    color: colors.textDark,
  },
  "&:disabled": {
    color: colors.textPlaceholder,
  },
  height: size === "small" ? 30 : size === "medium" ? 40 : 50,
}));

const InputPrimary = styled("input")<{ size?: "medium" | "large" | "small" }>(
  ({ size }) => ({
    height: size === "small" ? 30 : size === "medium" ? 40 : 50,
    color: colors.white,
    backgroundColor: "transparent",
    border: 0,
  })
);

const Row = styled("div")<{
  gap?: string | number;
  justifyContent?:
    | "center"
    | "end"
    | "flex-end"
    | "flex-start"
    | "start"
    | "space-around"
    | "space-between"
    | "space-evenly"
    | "stretch";
  flexDirection?: "column" | "column-reverse" | "row" | "row-reverse";
  alignItems?:
    | "center"
    | "end"
    | "flex-end"
    | "flex-start"
    | "self-end"
    | "self-start"
    | "start";
}>(({ gap, justifyContent, flexDirection, alignItems }) => ({
  display: "flex",
  gap,
  justifyContent: justifyContent ? justifyContent : undefined,
  flexDirection: flexDirection ? flexDirection : undefined,
  alignItems: alignItems ? alignItems : undefined,
}));

const Column = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
}));

export { Flex, ButtonPrimary, Row, ButtonPrimaryOutline, InputPrimary, Column };
