import { styled } from "@mui/material";
import { Column } from "components/Global/styles";
import { colors } from "utils/colors";

const BackGroundWrapper = styled("div")(() => ({
  backgroundImage: `url(${require("../../assets/images/backgroundblur.png")})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
}));

const ContainerContent = styled("div")(() => ({
  minHeight: "100%",
  display: "flex",
  justifyContent: "center",
  paddingTop: 10,
}));

const MainWrapper = styled("div")(() => ({
  minHeight: "calc(100vh - 100px)",
}));

const Title = styled("p")(() => ({
  fontSize: 34,
  fontWeight: "bold",
  color: colors.textLight,
  textAlign: "center",
}));

const Description = styled("p")(() => ({
  fontSize: 20,
  fontWeight: "400",
  color: colors.textPlaceholder,
  textAlign: "center",
}));

const TitleCard = styled("p")(() => ({
  fontSize: 22,
  fontWeight: "bold",
  color: colors.textLight,
}));

const Card = styled("div")(() => ({
  boxShadow: "rgba(0, 0, 0, 0.1) 0px 5px 10px",
  backgroundColor: colors.backgroundCard,
  padding: "12px 16px",
  borderRadius: 16,
  margin: 10,
  // maxWidth: 400,
}));

const TextTitle = styled("text")(() => ({
  fontSize: 16,
  fontWeight: "500",
  color: colors.textPlaceholder,
}));

const TextValue = styled("text")(() => ({
  fontSize: 17,
  fontWeight: "500",
  color: colors.textLight,
}));

const TextPrice = styled("text")(() => ({
  fontSize: 14,
  fontWeight: "500",
  color: colors.textPlaceholder,
}));

const TextNoted = styled("text")(() => ({
  fontSize: 14,
  fontWeight: "500",
  color: colors.textLight,
}));

const TextError = styled("p")(() => ({
  fontSize: 13,
  fontWeight: "400",
  color: colors.error,
  margin: 0,
}));

const TextEstimate = styled("text")(() => ({
  fontSize: 14,
  fontWeight: "500",
  color: colors.textWarning,
}));

const SwapForm = styled("div")<{ error?: boolean }>(({ error }) => ({
  border: `1px solid ${error ? colors.error : colors.border}`,
  padding: "6px 8px",
  borderRadius: 10,
}));

const ButtonSwap = styled("div")(() => ({
  borderRadius: "100%",
  border: `1px solid ${colors.primary}`,
  height: 30,
  width: 30,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export {
  BackGroundWrapper,
  MainWrapper,
  Title,
  Description,
  Card,
  TextTitle,
  TextValue,
  TitleCard,
  SwapForm,
  TextPrice,
  TextNoted,
  ButtonSwap,
  TextEstimate,
  TextError,
  ContainerContent,
};
