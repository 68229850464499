import { useTranslation, Trans } from "react-i18next";

const useLanguage = (keys?: string[] | string) => {
  const { t: translate, i18n } = useTranslation(keys);

  const t = (
    i18nKey: string,
    options?: { ns?: string; values?: Record<string, any> }
  ) => (
    <Trans
      t={translate}
      i18nKey={i18nKey}
      {...options}
      components={{
        text: <text className="whitespace-nowrap" />,
      }}
    />
  );
  return { t, i18n };
};

export default useLanguage;
