import * as React from "react";

const CopyIcon = ({
  width = 25,
  height = 25,
  color = "black",
}: {
  width?: number;
  height?: number;
  color?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="sc-dhKdcB hjGDST"
    // style={{
    //   width: 24,
    // }}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
  >
    <path d="M15 1H4c-1.1 0-2 .9-2 2v13c0 .55.45 1 1 1s1-.45 1-1V4c0-.55.45-1 1-1h10c.55 0 1-.45 1-1s-.45-1-1-1Zm4 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2Zm-1 16H9c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1h9c.55 0 1 .45 1 1v12c0 .55-.45 1-1 1Z" />
  </svg>
);
export default CopyIcon;
